import React from 'react'
import Loader from './Loader'

function LoaderWrapper(props) {
    return (
        <>
            {/* This will work only when u set initialState --> null(props?.data) */}
            {!props.data ? <Loader /> :
                props?.data?.length ?
                    props.children
                    : props?.noDataMsg
            }
        </>
    )
}

export default LoaderWrapper
import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import HeadingHeader from '../global-components/HeadingHeader';

function SuperAdminDashboard() {
  const navigateTo = useNavigate("/brand-admin")
  const usrDetail = JSON.parse(localStorage.getItem("userDetail"));
  useEffect(() => {
    if (usrDetail.role === "BA") {
      navigateTo('/brand-admin')
    }
  }, [])
  return (
    <div >
      <HeadingHeader title="Welcome" subTitle="Dashboard" />
    </div>
  )
}

export default SuperAdminDashboard;

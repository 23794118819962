import React, { useState } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import AllTaskListForAdmin from './task_list/all-task-list';
import ApprovedTasksList from './task_list/ApprovedTasksList';
import RejectedTaskList from './task_list/RejectedTaskList';
import moment from 'moment';
import { BsFilterRight } from 'react-icons/bs';
function VerifyTask() {

    return (

        <div className='container-fluid cs-date' id="branch_task" >
            <div className="py-3"></div>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first" unmountOnExit mountOnEnter>
                <Row >

                    <Col sm={12}>

                        <Nav variant="pills" className=" justify-content-center ">

                            <Nav.Item>
                                <Nav.Link eventKey="first">Verify Task</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">Approved Task</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="third">Rejected Task</Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item>
                                <Nav.Link eventKey="four">MONTHLY</Nav.Link>
                            </Nav.Item> */}
                        </Nav>
                    </Col>
                    <div className="py-3"></div>
                    <Col sm={12}>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <AllTaskListForAdmin />
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <ApprovedTasksList />
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <RejectedTaskList />
                            </Tab.Pane>
                            <Tab.Pane eventKey="four">

                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>


        </div>
    )
}

export default VerifyTask
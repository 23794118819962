import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react'
import { Accordion } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { FaRegTrashAlt } from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';
import { useRecoilState } from 'recoil';
import { API_CONSTANTS, API_METHOD } from '../../../../../api/apiConstants'
import { API_CALL } from '../../../../../api/api_calls_services';
import { accordionDefaultActiveKeyRecoilState, assignedTaskDataRecoilState } from '../../../../../recoil_states/taskEditAtoms';
import JsonViewer from '../../../../global-components/JsonViewer';
import { confirmDeleteAlert } from '../../../../global-components/utils/swalAlerts';
import ViewModal from './view-modal';

function AssignTaskTable() {
    const [assignedTaskData, setAssignedTaskData] = useState(null)
    const [AssignedTaskDataAtom, setAssignedTaskDataAtom] = useRecoilState(assignedTaskDataRecoilState)
    const [openActionMenuAccordion, setOpenActionMenuAccordion] = useRecoilState(accordionDefaultActiveKeyRecoilState) // open accordion state
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        getAllAssignedTask();
    }, [])
    const getAllAssignedTask = async () => {
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_ASSIGNED_TASK,
            method: API_METHOD.GET,
        }
        let result = await API_CALL(request)
        if (result.statusCode === 200) {
            let sortedArray = result?.data?.sort((a, b) => a?.userDetail?.userName?.localeCompare(b?.userDetail?.userName));
            setAssignedTaskData(sortedArray)
        }
    }
    const onEditAssignTask = (assignedTaskDataParam) => {
        setOpenActionMenuAccordion({ accordionActiveKey: "0", tabActiveKey: "Assign", triggerReRender: Math.random() })//the defaultevent key for accordion was "0" so using "0"
        setAssignedTaskDataAtom(assignedTaskDataParam)//set clicked checklist data
        document.body.scrollTop = document.documentElement.scrollTop = 0;

    }
    const onDeleteAssignTask = async (assignedTaskDataParam) => {
        const isClicked = await confirmDeleteAlert({ title: "Are you sure want to delete this row?", cancelText: "No", confirmText: "Yes" });
        if (isClicked.isConfirmed) {
            setLoading(true);
            const requestBodyParam = {
                method: 'DELETE',
                redirect: 'follow'
            };
            const request = {
                apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.DELETE_ASSIGNED_TASK + `/${assignedTaskDataParam?.id}`,
                method: API_METHOD.DELETE,
                requestBodyParams: requestBodyParam,
            }
            let result = await API_CALL(request)
            if (result.statusCode === 200) {
                getAllAssignedTask()
                toast.success("Checklist deleted successfully")
            }
        }

    }

    return (
        <div>
            {assignedTaskData ?
                <div className='sub-checklist'>

                    <table className="table-cust">
                        <thead>
                            <tr>
                                <th className='w-50'>
                                    Users
                                </th>
                                <th>
                                    Expand
                                </th>
                            </tr>

                        </thead>
                    </table>
                    <Accordion defaultActiveKey={0} flush>{
                        assignedTaskData.map((assignTaskDataItems, index) => (
                            <Accordion.Item eventKey={index} className="py-1">
                                <Accordion.Header><span className=' text-muted fz-14px'>  {index + 1}.</span> <span className='text-dark mx-1 text-capitalize '>{assignTaskDataItems?.userDetail?.userName}</span></Accordion.Header>
                                <Accordion.Body>
                                    <div className="px-3">
                                        <h6 className='text-muted'>Assigned checklist for {assignTaskDataItems?.userDetail?.userName}:</h6>
                                        <div className="table-responsive">
                                            <table className="table-cust">
                                                <thead>

                                                    <tr>
                                                        <th>
                                                            SNo
                                                        </th>
                                                        <th>
                                                            Title
                                                        </th>
                                                        <th>
                                                            Description
                                                        </th>
                                                        {/* <th>
                                                        TaskState
                                                    </th> */}
                                                        <th>
                                                            Action
                                                        </th>
                                                    </tr>

                                                </thead>
                                                <tbody >
                                                    {assignTaskDataItems?.checkList ?

                                                        assignTaskDataItems?.checkList?.map((taskData, index) => (
                                                            <tr key={taskData?.id}>
                                                                <td >
                                                                    {(index + 1).toString().padStart(2, '0')}
                                                                </td>
                                                                <td >
                                                                    {taskData?.title}

                                                                </td>
                                                                <td >
                                                                    {taskData?.description}

                                                                </td>
                                                                {/* <td >
                                                                {taskData?.taskState}

                                                            </td> */}
                                                                <td >
                                                                    {/* <button className="border-0 bg-transparent  text-primary"><AiOutlineEye /></button> */}
                                                                    <button className="border-0 bg-transparent text-success me-2" onClick={() => onEditAssignTask(assignTaskDataItems)}><FiEdit size={17} /></button>
                                                                    <button className="border-0 bg-transparent  text-danger" onClick={() => onDeleteAssignTask(assignTaskDataItems)}><FaRegTrashAlt /></button>

                                                                </td>

                                                            </tr>
                                                        ))
                                                        : <tr>
                                                            <td colSpan={5} className="text-muted">
                                                                No SubCheckList Available
                                                            </td>

                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))
                    } </Accordion>
                </div> : ""}

            <JsonViewer data={assignedTaskData} />

        </div>
    )
}

export default AssignTaskTable
import React, { useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import { toast } from 'react-hot-toast'
import { AiOutlineEye } from 'react-icons/ai'
import { FaRegTrashAlt } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'
import { useRecoilState } from 'recoil'
import { API_CONSTANTS, API_METHOD } from '../../../../../api/apiConstants'
import { API_CALL } from '../../../../../api/api_calls_services'

// import CheckListEdit from './checklist-edit'
// import SubCheckListEdit from './subtasklist-edit'

import { trackGetAllCheckListStateChange } from '../../../../../recoil_states/getchecklist'
import { accordionDefaultActiveKeyRecoilState, subCheckListDataRecoilState } from '../../../../../recoil_states/taskEditAtoms'
import JsonViewer from '../../../../global-components/JsonViewer'
import { confirmDeleteAlert } from '../../../../global-components/utils/swalAlerts'
import ViewModal from './view-modal'

function SubTakListTableData() {
    const [show, setShow] = useState(false);//parent state for view modal
    const handleClose = () => setShow(false);//parent function for state update view modal
    const [checkListRowData, setViewCheckListRowData] = useState('');
    const [allCheckList, setAllCheckList] = useState([]);
    const [checkListState, setCheckListState] = useRecoilState(trackGetAllCheckListStateChange)
    const [loading, setLoading] = useState(false);
    const [openActionMenuAccordion, setOpenActionMenuAccordion] = useRecoilState(accordionDefaultActiveKeyRecoilState) // open accordion state
    const [subCheckListDataAtom, setSubCheckListDataAtom] = useRecoilState(subCheckListDataRecoilState)
    useEffect(() => {
        getAllCheckList();
    }, [checkListState]);


    // const onDeleteCheckList = async (dataId) => {
    //     setLoading(true);
    //     const requestBodyParam = {
    //         method: 'DELETE',
    //         redirect: 'follow'
    //     };
    //     const request = {
    //         apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.DELETE_CHECK_LIST + `/${dataId}`,
    //         method: API_METHOD.DELETE,
    //         requestBodyParams: requestBodyParam,
    //     }
    //     let result = await API_CALL(request)
    //     if (result.statusCode === 200) {
    //         setCheckListState(result.message);
    //         setLoading(false);
    //     } else if (result.status > 200) {
    //         alert(result.message);
    //     }
    // }
    const onEditCheckList = (gettingSelectedSubCheckListData, checkListId) => {
        setOpenActionMenuAccordion({ accordionActiveKey: "0", tabActiveKey: "subChecklist", triggerReRender: Math.random() })//the defaultevent key for accordion was "0" so using "0"
        setSubCheckListDataAtom({ ...gettingSelectedSubCheckListData, checkList_id: checkListId })//set clicked checklist data
    }
    const getAllCheckList = async () => {
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_ALL_CHECK_LIST,
            method: API_METHOD.GET,
        }
        let result = await API_CALL(request)
        if (result.statusCode === 200) {
            setAllCheckList(result.message);
        }
    }

    const handleViewChecklist = (clickedRowData) => {
        setViewCheckListRowData(clickedRowData);
        setShow(true)
    }
    const onDeleteSubCheckList = async (subListData) => {
        const isClicked = await confirmDeleteAlert({ title: "Are you sure want to delete this row?", cancelText: "No", confirmText: "Yes" });
        if (isClicked.isConfirmed) {
            setLoading(true);
            const requestBodyParam = {
                method: 'DELETE',
                redirect: 'follow'
            };
            const request = {
                apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.DELETE_SUB_CHECKLIST + `/${subListData?.id}`,
                method: API_METHOD.DELETE,
                requestBodyParams: requestBodyParam,
            }
            let result = await API_CALL(request)
            if (result.statusCode === 200) {
                getAllCheckList();
                toast.success("Checklist deleted successfully")
            }
        }
    }
    return (
        <>
            <h6 className='pb-1'>SubTakListTableData</h6>

            {allCheckList ?
                <div className='sub-checklist'>

                    <table className="table-cust">
                        <thead>
                            <tr>
                                <th className='w-50'>
                                    CheckList Title
                                </th>
                                <th>
                                    Expand
                                </th>
                            </tr>

                        </thead>
                    </table>
                    <Accordion defaultActiveKey={0} flush>{
                        allCheckList.map((checkListItems, index) => (
                            <Accordion.Item eventKey={index} key={checkListItems?.id} className="py-1">
                                <Accordion.Header><span className=' text-muted fz-14px'>  {index + 1}.</span> <span className='text-dark mx-1 text-capitalize '>{checkListItems?.title}</span></Accordion.Header>
                                <Accordion.Body>
                                    <div className="px-3">
                                        <h6 className='text-muted'>SubCheckList:</h6>

                                        <table className="table-cust">
                                            <thead>

                                                <tr>
                                                    <th>
                                                        SNo
                                                    </th>
                                                    <th>
                                                        Title
                                                    </th>
                                                    <th>
                                                        Description
                                                    </th>
                                                    {/* <th>
                                                        TaskState
                                                    </th> */}
                                                    <th>
                                                        Action
                                                    </th>
                                                </tr>

                                            </thead>
                                            <tbody >
                                                {checkListItems?.subCheckList?.length ?
                                                    checkListItems?.subCheckList.map((subList, index) => (
                                                        <tr key={subList?.id}>
                                                            <td >
                                                                0{index + 1}
                                                            </td>
                                                            <td >
                                                                {subList?.title}
                                                            </td>
                                                            <td >
                                                                {subList?.description}
                                                            </td>
                                                            {/* <td >
                                                                {subList.taskState === "INACTIVE" ? <button className="task-active-btn bg-danger" onClick={() => alert(subList)}>{subList.taskState}</button> : ""}
                                                                {subList.taskState === "ACTIVE" ? <button className="task-active-btn " onClick={() => alert(subList)}>{subList.taskState}</button> : ""}
                                                            </td> */}
                                                            <td >
                                                                {/* <button className="border-0 bg-transparent  text-primary"><AiOutlineEye /></button> */}
                                                                <button className="border-0 bg-transparent text-success me-2" onClick={() => onEditCheckList(subList, checkListItems?.id)}><FiEdit size={17} /></button>
                                                                <button className="border-0 bg-transparent  text-danger" onClick={() => onDeleteSubCheckList(subList)}><FaRegTrashAlt /></button>

                                                            </td>
                                                        </tr>
                                                    ))
                                                    : <tr>
                                                        <td colSpan={5} className="text-muted">
                                                            No SubCheckList Available
                                                        </td>

                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>

                        ))
                    } </Accordion>
                </div>
                : <ViewModal checkListRowData={checkListRowData} handleClose={handleClose} show={show} />
            }
            <JsonViewer data={allCheckList} />

        </>
    )
}
export default SubTakListTableData
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FaCamera } from 'react-icons/fa';
import { IoReloadOutline } from 'react-icons/io5';
import { BsCheck2 } from 'react-icons/bs';
import { RiCameraSwitchFill } from 'react-icons/ri';
import Webcam from 'react-webcam';
import { API_CONSTANTS, API_METHOD } from '../../../../api/apiConstants';
import { API_CALL } from '../../../../api/api_calls_services';
import camStyles from "../../../../css/liveCam.module.css";
import { confirmSubmit } from '../../../global-components/utils/swalAlerts';
import JsonViewer from '../../../global-components/JsonViewer';
import { Accordion } from 'react-bootstrap';
function LivePhotoTask({ getAllAssignedTask, modalSubTaskData, setIsLivePhoto, setShowViewModal }) {
    const FACING_MODE_ENVIRONMENT = "environment";
    const FACING_MODE_USER = "user";
    const [img, setImg] = useState(null);
    const [comment, setComment] = useState(null)
    const [loading, setLoading] = useState(false)
    const [ImgPreviewList, setImgPreviewList] = useState([])
    const webcamRef = useRef(null);
    const imageRef = useRef(null);
    const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);
    useEffect(() => {
        setImg(null)
    }, [])
    const videoConstraints = {
        width: 1280,
        height: 720,
        screenshotQuality: 1,
        facingMode: FACING_MODE_USER, //{ exact: "environment" } for rear camera
        aspectRatio: 16 / 9,

    };
    const capture = useCallback(() => {
        // {
        //     width: 1280,
        //     height: 720
        // }
        imageRef.current = webcamRef.current.getScreenshot();
        setImg(imageRef.current);
    }, [webcamRef]);


    const handleClick = useCallback(() => {
        setFacingMode(
            prevState =>
                prevState === FACING_MODE_USER
                    ? FACING_MODE_ENVIRONMENT
                    : FACING_MODE_USER
        );
    }, []);
    const onUpload = async () => {

        const isClicked = await confirmSubmit({ title: "Are you sure to upload?", cancelText: "No", confirmText: "Yes" });
        const modifiedImageListArray = ImgPreviewList.map(element => {
            // Remove "data:image/png;base64," prefix
            return element.substring("data:image/png;base64,".length);
        });

        if (isClicked.isConfirmed) {
            // modalSubTaskData.rejectStatus ? API_CONSTANTS.BRANCH_CHECK_LIST_UPDATE : API_CONSTANTS.BRANCH_CHECK_LIST_UPDATE
            setLoading(true)
            let requestBodyParam;
            let request;

            if (modalSubTaskData.rejectStatus) {
                requestBodyParam = JSON.stringify({
                    "comments": comment,//This key was not required just sending
                    "checkList": modalSubTaskData?.checkListId,
                    "subCheckList": modalSubTaskData?.id,
                    "myList": modifiedImageListArray

                });


                request = {
                    apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.UPDATE_REJECTED_SUB_LIST_IMAGE,
                    method: API_METHOD.PUT,
                    requestBodyParams: requestBodyParam,
                }
            } else {
                requestBodyParam = JSON.stringify({
                    "comments": comment,
                    "checkList": modalSubTaskData?.checkListId,
                    "subCheckList": modalSubTaskData?.id,
                    "myList": modifiedImageListArray

                })
                request = {
                    apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.BRANCH_CHECK_LIST_UPDATE,
                    method: API_METHOD.POST,
                    requestBodyParams: requestBodyParam,
                }
            }

            let result = await API_CALL(request)
            if (result.statusCode === 200) {
                getAllAssignedTask();
                setLoading(false)
                toast.success("Uploaded")
                setIsLivePhoto(false)
                setImg(null)
                setShowViewModal(false)
            }
        }
    }
    const onConfirm = () => {
        setImgPreviewList([...ImgPreviewList, imageRef.current]);
        setImg(null)
    }
    return (
        <div>
            <div className="Container">

                {img === null ? (
                    <>
                        <Webcam
                            audio={false}
                            mirrored={facingMode == FACING_MODE_ENVIRONMENT ? false : true}
                            ref={webcamRef}
                            forceScreenshotSourceSize
                            width="100%"
                            height={500}
                            screenshotFormat="image/png"
                            videoConstraints={{
                                ...videoConstraints,
                                facingMode
                            }}
                        />
                        <div className='w-100 text-center'>

                            <button onClick={capture} className={`${camStyles.optionsContainer} ${camStyles.cam} `}><FaCamera size={20} /></button>
                            <button onClick={handleClick} className={`${camStyles.optionsContainer} ${camStyles.switch} mx-2`}><RiCameraSwitchFill size={22} /></button>
                        </div>
                    </>
                ) : (
                    <>
                        {/* <video src={img} controls></video> */}

                        {/* <img src={img} alt="task" /> */}
                        <img src={img} alt="task" />
                        <div className='w-100 text-center'>

                            <button onClick={() => setImg(null)} className={`${camStyles.optionsContainer} ${camStyles.retake} `}><IoReloadOutline size={21} /></button>
                            <button onClick={onConfirm} className={`${camStyles.optionsContainer} ${camStyles.right} ms-2`}><BsCheck2 size={25} /></button>

                        </div>

                    </>
                )}
                {/* <textarea type="text" placeholder='Add comments' className='py-2 default-input w-100' cols={"10"} onChange={e => setComment(e.target.value)} /> */}
                {/* -------Preview */}
                {ImgPreviewList.length ?
                    <div className={`${camStyles.capturePrevHeight} `}>
                        <Accordion defaultActiveKey={0}>
                            {
                                ImgPreviewList.length ?
                                    ImgPreviewList.map((value, index, array) => (
                                        <Accordion.Item eventKey={index} key={index + 1}>
                                            <Accordion.Header>Capture Preview {index + 1} </Accordion.Header>
                                            <Accordion.Body>
                                                <img src={value} alt={`Pic ${index}`} />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))
                                    : ""
                            }
                        </Accordion>
                    </div> : ""}
                {/* ----------Upload/cancel btn----------- */}
                <div className='text-end'>
                    {ImgPreviewList.length ?
                        <button className={"border-0 bg-success fz-14px fw-500 text-white px-3 py-1 rounded-pill mb-2 mx-2"} onClick={onUpload} disabled={loading}>{loading ?
                            <>
                                <div className="spinner-border  rounded-circle text-white spinner-border-sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div> Please wait...
                            </> : "Upload"}</button>
                        : ""}

                    <button className={"border-0 bgTomato fz-14px fw-500 text-white mt-3 px-3 py-1 rounded-pill"} onClick={() => setIsLivePhoto(false)}> Cancel</button>


                </div>
            </div>
        </div>
    )
}

export default LivePhotoTask
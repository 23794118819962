import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { AiOutlineVideoCameraAdd } from "react-icons/ai";
import { BsPlusSquareDotted } from "react-icons/bs";
import { TbCameraPlus } from "react-icons/tb";
import { BiBlock } from "react-icons/bi";
import { API_CONSTANTS, API_METHOD } from "../../../api/apiConstants";
import { API_CALL } from "../../../api/api_calls_services";
import JsonViewer from "../../global-components/JsonViewer";
import LoaderWrapper from "../../global-components/LoaderWrapper";
import BootstrapModal from "../../global-components/utils/BootsrapModal";
import LivePhotoTask from "./livePhoto/LivePhotoTask";
import LiveVideoTask from "./liveVideo/LiveVideoTask";
const btnStyle1 = {
  background: "#007BFF",
  color: "white",
  padding: "6px 15px",
  borderRadius: "58px",
};
const btnStyle2 = {
  background: "#0d2839eb",
  color: "white",
  padding: "6px 15px",
  borderRadius: "58px",
};

function BranchManagerDaily() {
  const [showViewModal, setShowViewModal] = useState(false);
  const [modalSubTaskData, setModalSubTaskData] = useState(false);
  const [isLivePhotoOn, setIsLivePhoto] = useState(false);
  const [isLiveVideoOn, setIsLiveVideo] = useState(false);
  const [allTaskData, setAllTaskData] = useState(null);
  useEffect(() => {
    getAllAssignedTask();
  }, []);
  const getAllAssignedTask = async () => {
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_DAILY_TASK,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.statusCode === 200) {
      setAllTaskData(result.data);
    }
  };
  const onAction = (checkListData, subCheckList, indx) => {
    setModalSubTaskData({
      checkListId: checkListData.checkId,
      ...subCheckList,
      indx,
    });
    setIsLiveVideo(false);
    setIsLivePhoto(false);
    setShowViewModal(true);
  };
  const validationAlert = () => {};
  return (
    <div className="container-fluid ">
      <BootstrapModal
        fullscreen={false}
        isShowModal={showViewModal}
        setIsModal={setShowViewModal}
        title={<h5 className={``}>Task Detail</h5>}
        body={
          <div>
            <h5 className="text-capitalize">
              {modalSubTaskData.indx}. {modalSubTaskData?.title}
            </h5>
            <p className="my-2">
              <b>Description:</b> {modalSubTaskData?.description}
            </p>
            <JsonViewer data={modalSubTaskData} />
            {/* ------Photo-------- */}
            {isLivePhotoOn ? (
              // ----Live photo window-------
              <LivePhotoTask
                getAllAssignedTask={getAllAssignedTask}
                setIsLivePhoto={setIsLivePhoto}
                modalSubTaskData={modalSubTaskData}
                setShowViewModal={setShowViewModal}
              />
            ) : (
              ""
            )}
            {/* --------Photo End------- */}
            {/* ------Video-------- */}
            {isLiveVideoOn ? (
              // ----Live photo window-------
              <LiveVideoTask
                getAllAssignedTask={getAllAssignedTask}
                setIsLiveVideo={setIsLiveVideo}
                modalSubTaskData={modalSubTaskData}
                setShowViewModal={setShowViewModal}
              />
            ) : (
              ""
            )}
            {/* ------Video end-------- */}
            {/* ------------Options------ */}
            {isLivePhotoOn || isLiveVideoOn ? (
              ""
            ) : (
              <div className="d-flex">
                <div className="d-flex align-items-center">
                  <button
                    type="button"
                    className="border-0"
                    style={btnStyle1}
                    onClick={() => setIsLivePhoto(true)}
                  >
                    <TbCameraPlus size={16} /> Upload photo
                  </button>
                </div>
                <div className="d-flex align-items-center">
                  <button
                    type="button"
                    className="border-0 ms-3"
                    style={btnStyle2}
                    onClick={() => {
                      setIsLiveVideo(true);
                    }}
                  >
                    <AiOutlineVideoCameraAdd size={16} /> Upload video
                  </button>
                </div>
              </div>
            )}
          </div>
        }
      />
      <LoaderWrapper data={allTaskData}>
        <div>
          {allTaskData ? (
            <div className="sub-checklist">
              <table className="table-cust">
                <thead>
                  <tr>
                    <th className="w-50">CheckList</th>
                    <th>Expand</th>
                  </tr>
                </thead>
              </table>
              <Accordion defaultActiveKey={0} flush>
                {allTaskData.map((assignTaskDataItems, mainIndex) =>
                  assignTaskDataItems?.checkList?.length == 0 ? (
                    <h6 className="text-center text-muted py-4 fw-500">
                      No daily task present
                    </h6>
                  ) : (
                    assignTaskDataItems?.checkList?.map(
                      (checkListData, checkListIndex) => (
                        <Accordion.Item
                          eventKey={checkListIndex}
                          key={checkListData.checkId + checkListIndex}
                          className="py-1"
                        >
                          <Accordion.Header>
                            <span className=" text-muted fz-14px">
                              {" "}
                              {checkListIndex + 1}.
                            </span>{" "}
                            <span className="text-dark mx-1 text-capitalize ">
                              {checkListData?.title}
                            </span>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="px-3">
                              <h6 className="text-muted">Sub checklist:</h6>
                              <table className="table-cust">
                                <thead>
                                  <tr>
                                    <th>SNo</th>
                                    <th>Title</th>
                                    <th>Description</th>
                                    {/* <th>
                                TaskState
                              </th> */}
                                    <th>Action</th>
                                    {/* <th>
                                  Status
                                </th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {checkListData?.subCheckList?.length ? (
                                    checkListData?.subCheckList?.map(
                                      (subCheckList, indx) => (
                                        <tr key={subCheckList?.id + indx}>
                                          <td>
                                            {(indx + 1)
                                              .toString()
                                              .padStart(2, "0")}
                                          </td>
                                          <td>{subCheckList.title}</td>
                                          <td>{subCheckList.description}</td>

                                          {/* {subCheckList.value ? "Value was true show btn ///" : "don't show value was false///"}
                                        {subCheckList.rejectStatus ? "rejectStatus true show btn" : "rejectStatus false don't show btn"} */}
                                          {/* <td role={"button"} onClick={() => onAction(checkListData, subCheckList, (indx + 1).toString().padStart(2, '0'))}>
                                          <BsPlusSquareDotted />
                                        </td> */}
                                          {subCheckList.value ||
                                          subCheckList.rejectStatus ? (
                                            <td
                                              role={"button"}
                                              onClick={() =>
                                                onAction(
                                                  checkListData,
                                                  subCheckList,
                                                  (indx + 1)
                                                    .toString()
                                                    .padStart(2, "0")
                                                )
                                              }
                                            >
                                              <BsPlusSquareDotted />
                                            </td>
                                          ) : (
                                            <td
                                              role={"button"}
                                              onClick={validationAlert}
                                              title="This task was already submitted today"
                                            >
                                              <BiBlock />
                                            </td>
                                          )}

                                          {/* <td className={`${subCheckList?.status}`}>
                                      {subCheckList?.status}
                                    </td> */}
                                        </tr>
                                      )
                                    )
                                  ) : (
                                    <tr>
                                      <td colSpan={5} className="text-muted">
                                        No SubCheckList Available
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      )
                    )
                  )
                )}{" "}
              </Accordion>
            </div>
          ) : (
            ""
          )}
        </div>
      </LoaderWrapper>
      <JsonViewer data={allTaskData} />
    </div>
  );
}
export default BranchManagerDaily;

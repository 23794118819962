import React from 'react'

function HeadingHeader({ title, subTitle }) {
    return (
        <div className="bg-white py-30px">
            <div className=" px-4">
                <h2 className="fw-800">{title}</h2>
                <p className="text-muted opacity-75 font-os fw-600 fz-14px m-0">{subTitle}</p>
            </div>
        </div>
    )
}

export default HeadingHeader
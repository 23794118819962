import React, { useEffect, useState } from 'react';
import { Accordion, Modal } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { BsEye, BsFilterRight, BsPlusSquareDotted } from "react-icons/bs";
import { API_CONSTANTS, API_METHOD } from '../../../../api/apiConstants';
import { API_CALL } from '../../../../api/api_calls_services';
import JsonViewer from '../../../global-components/JsonViewer';
import LoaderWrapper from '../../../global-components/LoaderWrapper';
import moment from 'moment/moment';
import { confirmSubmit } from '../../../global-components/utils/swalAlerts';
import Swal from 'sweetalert2';
import camStyles from "../../../../css/liveCam.module.css";
const imgStyle = { "width": "100%", "height": "350px", objectFit: "cover", "borderRadius": "4px" }
function AllTaskListForAdmin() {
  const [allTaskData, setAllTaskData] = useState(null);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [taskFilter, setTaskFilter] = useState("DAILYTASK");
  const [previewData, setPreviewData] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    setAllTaskData(null)
    getAllAssignedApprovePendingTask();
  }, [date, taskFilter])
  const getAllAssignedApprovePendingTask = async () => {
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_APPROVE_PENDING_TASK + `/${date}` + `/${taskFilter}`,
      method: API_METHOD.GET,
    }
    let result = await API_CALL(request)
    if (result.statusCode === 200) {
      setAllTaskData(result.data)
    }
  }
  const onApprove = async (clickedData) => {
    const isClicked = await confirmSubmit({ title: "Are you sure to Approve?", cancelText: "No", confirmText: "Yes" });
    if (isClicked.isConfirmed) {
      let request = {
        apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.APPROVE_SUB_CHECK_LIST + `/${clickedData?.assigned_id}`,
        method: API_METHOD.PUT,
        requestBodyParams: JSON.stringify({})
      }
      let result = await API_CALL(request)
      if (result.statusCode === 200) {
        getAllAssignedApprovePendingTask();
        toast.success("Task approved successfully!");
      }
    }
  }
  const onReject = async (clickedData) => {
    const { value: comment } = await Swal.fire({
      title: 'Enter the reason',
      input: 'textarea',
      inputPlaceholder: 'Type your comment here...',
      inputAttributes: {
        'aria-label': 'Comment',
      }, didOpen: () => {
        const modalElement = document.querySelector('.modal');
        modalElement.removeAttribute('tabindex');
      },
      showCancelButton: true,
      confirmButtonText: 'Submit',
      preConfirm: (input) => {
        if (!input) {
          Swal.showValidationMessage('Reason is required');
        }
        return input;
      },
    });

    if (comment) {
      // Handle the submitted comment
      let request = {
        apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.REJECT_SUB_CHECK_LIST + `/${clickedData?.assigned_id}`,
        method: API_METHOD.PUT,
        requestBodyParams: JSON.stringify({ comments: comment })
      }
      let result = await API_CALL(request)
      if (result.statusCode === 200) {
        getAllAssignedApprovePendingTask();
        toast.success("Task rejected successfully!");
        handleClose();
      }
    }

  }
  const onView = (data) => {
    setPreviewData(data)
    handleShow();
  }
  const renderedData = <LoaderWrapper data={allTaskData} noDataMsg={<h6 className='text-center text-muted'>No task present</h6>} >
    <div className='sub-checklist'>
      <table className="table-cust" >
        <thead>
          <tr>
            <th className='w-50'>
              Users
            </th>
            <th>
              Expand
            </th>
          </tr>
        </thead>
      </table >
      <Accordion defaultActiveKey={0} flush>
        {allTaskData?.map((item, index) => (
          <Accordion.Item eventKey={index} key={item.userId} className="py-1">
            <Accordion.Header><h6 className=" text-capitalize">User Name: <b>{item.userDetail.userName}</b> </h6> </Accordion.Header>
            <Accordion.Body>
              {item.checkList.map((checkItem, cIndx) => (
                <div key={checkItem.id}  >
                  <h5 className='fz-12px'>{cIndx + 1}. Check List:  {checkItem.title} </h5>
                  <h6 className='my-3'><b>Description</b>: {checkItem.description}</h6>
                  <span className='text-white px-3 py-1 rounded-4 fw-600 font-os fz-13px bg-secondary mb-3'>Sub Checklist / {checkItem?.taskType == "DAILYTASK" ? "Daily Task" : ""} {checkItem?.taskType == "WEEKLYTASK" ? "Weekly Task" : ""} {checkItem?.taskType == "MONTHLYTASK" ? "Monthly Task" : ""}</span>

                  <table className="table-cust">
                    <thead>
                      <tr>
                        <th>
                          SNo
                        </th>
                        <th>
                          Title
                        </th>
                        <th>
                          Description
                        </th>
                        <th className='w-25'>
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody  >
                      {
                        checkItem.subCheckList == null ?
                          <tr>
                            <td colSpan={5} className="text-muted">
                              No SubCheckList Available
                            </td>
                          </tr> :
                          checkItem.subCheckList.map((subItem, indx) => (
                            <tr key={subItem.sub_id}>
                              <td>
                                {(indx + 1).toString().padStart(2, '0')}
                              </td>
                              <td >
                                {subItem.title}
                              </td>
                              <td >
                                {subItem.description}
                              </td>
                              {subItem?.s3Locations?.length || subItem?.videoLocation ?
                                <td role={"button"} >
                                  <div className="d-flex justify-content-around">
                                    <button className='view-btn border-0 fz-14px rounded-4' onClick={() => onView(subItem)}>View </button>
                                    <button className='apprv-btn  border-0 fz-14px rounded-4' onClick={() => onApprove(subItem)}>Approve</button>
                                    <button className='rej-btn border-0 fz-14px rounded-4 ' onClick={() => onReject(subItem)} >Reject</button>
                                  </div>
                                </td> :
                                <td role={"button"} >
                                  <div className="d-flex justify-content-around text-danger">
                                    Not Completed
                                  </div>
                                </td>
                              }

                            </tr>
                          ))
                      }
                    </tbody>
                  </table>
                  <div className="my-3"></div>
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div >
  </LoaderWrapper >
  return (
    <>
      <div>

        <div className="pt-2 pb-4">
          <BsFilterRight /> <span className=' font-os fw-500 fz-14px text-dark'>Filter by date : </span> <input type='date' className='date' value={date} onChange={(e) => setDate(e.target.value)} />
          <span className='ms-2 font-os fw-500 fz-14px text-dark'>Task type: </span>
          <select type='date' className='date pe-5' value={taskFilter} onChange={(e) => setTaskFilter(e.target.value)}  >
            <option value={"DAILYTASK"}>Daily</option>
            <option value={"WEEKLYTASK"}>Weekly</option>
            <option value={"MONTHLYTASK"}>Monthly</option>
          </select>
        </div>
        {renderedData}
        <Modal
          show={show}
          size="lg"
          onHide={handleClose}
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <JsonViewer data={previewData} />
            <Modal.Title><>{previewData?.title} </></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><b>Description</b>: {previewData?.description}</p>

            <div className={`${camStyles.capturePrevHeight} `}>
              <Accordion defaultActiveKey={0} flush>
                {previewData?.s3Locations?.length ?
                  <>
                    {
                      previewData?.s3Locations?.map((uploadedItems, index, array) => (
                        uploadedItems?.imageLocations ?
                          <Accordion.Item eventKey={index} key={uploadedItems?.imageId + index}>
                            <Accordion.Header>Uploaded Preview {index + 1}</Accordion.Header>
                            <Accordion.Body>
                              <a href={uploadedItems?.imageLocations} target='_blank' className='pb-2 d-inline-block'>View full screen</a>
                              <img src={uploadedItems?.imageLocations} style={imgStyle} className=' d-block' />
                            </Accordion.Body>
                          </Accordion.Item>
                          :
                          <Accordion.Item eventKey={index} key={uploadedItems?.index + 1}>
                            <Accordion.Header>Uploaded Video Preview {index + 1}</Accordion.Header>
                            <Accordion.Body>
                              <video
                                src={uploadedItems?.videoLocations}
                                controls
                                width="100%"
                                height="480px">
                                <source src="movie.mp4" type="video/mp4" />
                                <source src="movie.ogg" type="video/ogg" />
                              </video>
                            </Accordion.Body>
                          </Accordion.Item>
                      ))
                    }
                  </> : "No Uploads Found!"
                }
              </Accordion>
            </div>
            <div className="py-2"></div>
            {/* <p><b>Comments</b>:</p> */}

            {/* <textarea value={previewData?.comments} className=' form-control shadow-none' readOnly rows={4}></textarea> */}
            <div className="d-flex justify-content-end mt-3">
              <div className="ms-auto">
                <button className='apprv-btn  border-0 fz-14px rounded-2 mx-3' onClick={() => onApprove(previewData)}>Approve</button>
                <button className='rej-btn border-0 fz-14px rounded-2 ' onClick={() => onReject(previewData)} >Reject</button>
              </div>
            </div>

          </Modal.Body>
        </Modal>
      </div>
      <div className="py-4"></div>
      <JsonViewer data={allTaskData} />
    </>
  )
}
export default AllTaskListForAdmin
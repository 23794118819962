import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react'
import { useTable } from 'react-table';
import { API_CONSTANTS, API_METHOD } from '../../api/apiConstants';
import { API_CALL } from '../../api/api_calls_services';
import { MdOutlineSkipPrevious } from 'react-icons/md'
import { MdOutlineSkipNext } from 'react-icons/md'
import { IoIosArrowBack } from 'react-icons/io'
import { FaRegTrashAlt } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'
import { AiOutlineEye } from 'react-icons/ai'
import { useRecoilState } from 'recoil';
import { refetchBrandAtomState } from '../../recoil_states/refetchBrandAtom';
import { editBrandAtomFunc } from '../../recoil_states/editBrandAtom';
import Swal from 'sweetalert2';
function CreatedBrandTable() {
    const [getBrand, setGetBrand] = useState([])
    const [getBrandTrigger, setGetBrandTrigger] = useRecoilState(refetchBrandAtomState)
    const [brandEditAtom, setBrandEditAtom] = useRecoilState(editBrandAtomFunc)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getAllBrand();
    }, [getBrandTrigger.brandRefetch])

    const getAllBrand = async () => {

        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.SUPER_ADMIN_GET_ALL_BRAND,
            method: API_METHOD.GET,
        }
        let result = await API_CALL(request)
        if (result.statusCode === 200) {
            setGetBrand(result.data)
        }
    }
    const onEditBrand = (clickedRowData) => {

        setBrandEditAtom({
            ...brandEditAtom,
            ...clickedRowData,
            accordionActiveKey: "0",
            accordionRefreshKey: Math.random(),
            refreshTabKey: Math.random(),
            tabActiveKey: "first",
            btnText: "Save"
        })
    }
    const data = React.useMemo(
        () => {
            const rowData = getBrand.map((items, i) => ({
                ...items, SNo: i + 1
            }
            ));
            return rowData
        },
        [getBrand]
    )

    const columns = React.useMemo(
        () => [
            {
                Header: '#',
                accessor: 'SNo', // accessor is the "key" in the data
            },
            {
                Header: 'BrandName',
                accessor: 'brandName',
            },
            {
                Header: 'ACTION',
                accessor: 'delete',
                Cell: row => (<div>
                    {/* <button className="border-0 bg-transparent  text-primary" onClick={() => alert(JSON.stringify(row.row.original))}><AiOutlineEye /></button> */}
                    <button className="border-0 bg-transparent text-success me-2" onClick={() => onEditBrand(row.row.original)}><FiEdit size={17} /></button>
                    <button className="border-0 bg-transparent  text-danger" onClick={() => onDeleteBrand(row.row.original.brandId)}><FaRegTrashAlt /></button>

                </div>),
            }
        ],
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data })

    const onDeleteBrand = async (brandId) => {
        setLoading(true);

        // imageUrl: 'https://cdn-icons-png.flaticon.com/512/6460/6460112.png',
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const requestBodyParam = {
                    method: 'DELETE',
                    redirect: 'no follow'
                };

                const request = {
                    apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.DELETE_BRAND + `${brandId}`,
                    method: API_METHOD.DELETE,
                    requestBodyParams: requestBodyParam,
                }
                let result = await API_CALL(request)
                if (result.statusCode === 200) {
                    setLoading(false);
                    getAllBrand();
                } else if (result.statusCode > 200) {
                    Swal.fire("Error", result.message);
                }
            }
        })
    }

    return (
        <>

            {getBrand.length == 0 ? <div className='text-center mt-3'><span className="spinner-border text-primary spinner-border-sm" role="status" aria-hidden="true"></span>
                <span className="visually-hidden">Loading...</span></div> :
                <table {...getTableProps()} className="table-cust mt-4" >
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()} className="style-last-header-row">
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            }
            <div className="py-4"></div>
        </>
    )

}

export default CreatedBrandTable
import React from 'react'
import HeadingHeader from '../global-components/HeadingHeader'
import emailAlertStyles from "../../css/emailAlerts.module.css"
import CreateUserImg from '../../images/CreateUserImg'
import SendEmailImg from '../../images/SendEmailImg'
import { Link } from 'react-router-dom'

function BrandAdminDashboard() {
    return (
        <>
            <HeadingHeader title={"Brand Admin"} subTitle={"Dashboard"} />
            <div className='container-fluid pt-5'>
                <div className="row">
                    <div className="col">
                        <Link to="/brand-admin/create_user" className='text-decoration-none'>
                            <div className={`position-relative px-5   ${emailAlertStyles.header}`}>
                                <h4 className="fw-800 m-0  text-muted text-center">
                                    Create User
                                </h4>
                            </div>

                            <div className="card border-0 px-5 pt-4 pb-4">

                                <CreateUserImg />

                            </div>
                        </Link>
                    </div>
                    <div className="col">
                        <Link to="/brand-admin/email" className='text-decoration-none'>
                            <div className={`position-relative px-5   ${emailAlertStyles.header}`}>

                                <h4 className="fw-800 m-0  text-muted text-center">Create Alerts</h4>
                            </div>

                            <div className="card border-0 px-5 pt-4 pb-4">
                                <SendEmailImg />
                            </div>
                        </Link>
                    </div>

                </div>
                <div className="my-5"></div>
                <div className="row">
                    <div className="col">
                        <Link to="/brand-admin/create-task" className='text-decoration-none'>
                            <div className={`position-relative px-5   ${emailAlertStyles.header}`}>
                                <h4 className="fw-800 m-0  text-muted text-center">
                                    Create Task
                                </h4>
                            </div>

                            <div className="card border-0 px-5 pt-4 pb-4">

                                <CreateUserImg />

                            </div>
                        </Link>
                    </div>
                    <div className="col">
                        <Link to="/brand-admin/verify-task" className='text-decoration-none'>
                            <div className={`position-relative px-5   ${emailAlertStyles.header}`}>
                                <h4 className="fw-800 m-0  text-muted text-center">
                                    Task Verification
                                </h4>
                            </div>

                            <div className="card border-0 px-5 pt-4 pb-4">
                                <CreateUserImg />
                            </div>
                        </Link>
                    </div>

                </div>
                <div className="my-5"></div>

            </div >
        </>

    )
}

export default BrandAdminDashboard
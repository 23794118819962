import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { API_CONSTANTS, API_METHOD } from "../../../api/apiConstants";
import { API_CALL } from "../../../api/api_calls_services";
import CreatedUserTable from "./createdUserTable";
import usergif from "../../../images/create-user.svg";
import Swal from "sweetalert2";
import HeadingHeader from "../../global-components/HeadingHeader";
import emailAlertStyles from "../../../css/emailAlerts.module.css";
import { toast } from "react-hot-toast";
import { useRecoilState } from "recoil";
import { onEditBrandUserAtom } from "../../../recoil_states/AtomsStore";
import JsonViewer from "../../global-components/JsonViewer";

function CreateBranchManagerUser() {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [triggerGetAllBrachManagers, setTriggerGetAllBrachManagers] =
    useState(false);
  const [onEditBrandUserDataState, setOnEditBrandUserDataState] =
    useRecoilState(onEditBrandUserAtom);
  useEffect(() => {
    if (onEditBrandUserDataState?.id) {
      let defaultValues = {};
      defaultValues.name = onEditBrandUserDataState?.name;
      defaultValues.phnumber = onEditBrandUserDataState?.phoneNo;
      defaultValues.email = onEditBrandUserDataState?.email;
      defaultValues.address = onEditBrandUserDataState?.address;
      defaultValues.password = onEditBrandUserDataState?.password;
      reset({ ...defaultValues });
    }
  }, [onEditBrandUserDataState]);

  const onSubmit = async (data) => {
    setLoading(true);
    if (onEditBrandUserDataState?.id) {
      // Updating user
      const requestBodyParam = JSON.stringify({
        name: data.name,
        email: data.email,
        address: data.address,
        phoneNo: data.phnumber,
        password: data.password,
        brandId: 1,
      });
      const request = {
        apiUrl:
          API_CONSTANTS.BASE_URL +
          API_CONSTANTS.UPDATE_USER_DETAIL +
          `${onEditBrandUserDataState?.id}`,
        method: API_METHOD.PUT,
        requestBodyParams: requestBodyParam,
      };
      let result = await API_CALL(request);

      if (result.statusCode === 200) {
        setLoading(false);
        setTriggerGetAllBrachManagers(Math.random());
        editReset();
        toast.success("User was updated successfully");
      }
    } else {
      // Creating user
      const requestBodyParam = JSON.stringify({
        name: data.name,
        email: data.email,
        address: data.address,
        phoneNo: data.phnumber,
        password: data.password,
        brandId: 1,
      });
      const request = {
        apiUrl:
          API_CONSTANTS.BASE_URL + API_CONSTANTS.CREATE_BRANCH_MANAGER_USER,
        method: API_METHOD.POST,
        requestBodyParams: requestBodyParam,
      };
      let result = await API_CALL(request);
      if (result.statusCode === 200) {
        setLoading(false);
        reset();
        toast.success("Branch manager registered successfully");
        setTriggerGetAllBrachManagers(Math.random());
      }
    }
  };
  const imgStyle = {
    objectFit: "contain",
    height: "480px",
  };
  const editReset = () => {
    setOnEditBrandUserDataState(null);
    let defaultValues = {};
    defaultValues.name = "";
    defaultValues.phnumber = "";
    defaultValues.email = "";
    defaultValues.address = "";
    defaultValues.password = "";
    reset({ ...defaultValues });
    setLoading(false);
  };
  return (
    <>
      <JsonViewer data={onEditBrandUserDataState} />
      <HeadingHeader title={"Create Branch User"} subTitle={"Create User"} />
      <div className="px-lg-4">
        <div className="container py-4">
          <div className="row flex-row-reverse">
            <div className=" col-lg-6 col-sm-12  m-auto">
              <div
                className={`position-relative px-5   ${emailAlertStyles.header}`}
              >
                <p className="m-0 font-os text-muted fw-600 fz-14px ">
                  Create Branch User
                </p>
              </div>
              <div className="card border-0 px-5 pt-4 pb-4">
                <form onSubmit={handleSubmit(onSubmit)} className="email-frm ">
                  <input
                    placeholder="User Name"
                    autoComplete="new-password"
                    className="rounded default-input py-2"
                    type="text"
                    {...register("name", { required: true })}
                  />
                  <div className="text-danger">
                    {errors.name?.type === "required" && "Field is required"}
                  </div>

                  <input
                    placeholder="Phone number"
                    className="rounded default-input py-2"
                    type="text"
                    {...register("phnumber", { required: true })}
                  />
                  <div className="text-danger">
                    {errors.phnumber?.type === "required" &&
                      "Field is required"}
                  </div>

                  <input
                    placeholder="User EmailId"
                    className="rounded default-input py-2"
                    type="email"
                    {...register("email", { required: true })}
                  />
                  <div className="text-danger">
                    {errors.email?.type === "required" && "Field is required"}
                  </div>

                  <textarea
                    placeholder="Address"
                    className="rounded default-input py-2"
                    type="address"
                    {...register("address", { required: true })}
                  />
                  <div className="text-danger">
                    {errors.address?.type === "required" && "Field is required"}
                  </div>

                  <input
                    placeholder="Set User Password"
                    autoComplete="new-password"
                    className="rounded default-input py-2"
                    type="password"
                    {...register("password", { required: true })}
                  />
                  <div className="text-danger">
                    {errors.password?.type === "required" &&
                      "Field is required"}
                  </div>

                  <div className="text-end">
                    {onEditBrandUserDataState?.id ? (
                      <>
                        <button
                          className="bg-warning mx-3 text-white border-0 py-2 px-5 rounded-1"
                          type="button"
                          onClick={editReset}
                        >
                          Cancel
                        </button>
                        <input
                          type="submit"
                          disabled={loading}
                          value={loading ? "Please wait..." : "Save"}
                          className="bgTomato text-white border-0 py-2 px-5 rounded-1"
                        />
                      </>
                    ) : (
                      <input
                        type="submit"
                        disabled={loading}
                        value={loading ? "Please wait..." : "Submit"}
                        className="bgTomato text-white border-0 py-2 px-5 rounded-1"
                      />
                    )}
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 mt-auto">
              <img src={usergif} style={imgStyle} className="rounded d-block" />
            </div>
          </div>
        </div>
        <CreatedUserTable
          triggerGetAllBrachManagers={triggerGetAllBrachManagers}
        />
      </div>
    </>
  );
}

export default CreateBranchManagerUser;

import React from 'react'
import { Accordion, Tab, Row, Col, Nav } from 'react-bootstrap'
import { BiEditAlt } from "react-icons/bi"
import { useState } from 'react';
import RegisterBrandTable from './register-brand-table';
import CreatedBrandTable from './created-brand-table';
import CreateBrandForm from './create-brandform';
import RegisterBrandForm from './register-brand-form';
import { editBrandAtomFunc } from '../../recoil_states/editBrandAtom';
import { useRecoilState } from 'recoil';
import { editBrandUserAtomFunc } from '../../recoil_states/editBrandUserAtom';


function RegisterBrand() {
  const [checklistTab, setChecklistTab] = useState("CreateBrand");
  function ActiveCurrentTabClick(clicktab) {
    setChecklistTab(clicktab);
  }
  const [brandEditAtom, setBrandEditAtom] = useRecoilState(editBrandAtomFunc)
  const [editBrandUserAtom, setEditBrandUserAtom] = useRecoilState(editBrandUserAtomFunc);

  return (
    <div className='container pt-5'>
      <Accordion  key={brandEditAtom?.accordionRefreshKey}  defaultActiveKey={brandEditAtom?.accordionActiveKey}>
        <Accordion.Item eventKey="0" className="create-task-menu" >
          <Tab.Container id="left-tabs-example" key={brandEditAtom?.refreshTabKey} defaultActiveKey={brandEditAtom?.tabActiveKey}>
            <Accordion.Header><BiEditAlt className='me-1' />{checklistTab === "CreateBrand"?"Create New Brand":"Register Brand" }</Accordion.Header>
            <Row>
              <Col sm={12}>
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link eventKey="first" onClick={(e) => ActiveCurrentTabClick("CreateBrand")}> Create</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second" onClick={(e) => ActiveCurrentTabClick("RegisterBrand")}>Register</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
            <Accordion.Body>

              <Row>

                <Col sm={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <CreateBrandForm/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <RegisterBrandForm/>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>

            </Accordion.Body>
          </Tab.Container>
        </Accordion.Item>

      </Accordion>
 
      {checklistTab === "CreateBrand" ? <CreatedBrandTable/> : null}
      {checklistTab === "RegisterBrand" ? <RegisterBrandTable/> : null}

    </div>
  )
}

export default RegisterBrand
import React from 'react'

function NotFound404() {
    return (
        <div className='d-flex flex-column justify-content-center vh-100 align-items-center'>
            <div className="row">
                <div className="col">
                <img src='https://cdni.iconscout.com/illustration/premium/thumb/404-error-page-2952323-2451625.png' className='p-0 m-0' />
                </div>
            </div>
         
        </div>
    )
}

export default NotFound404
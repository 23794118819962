import Swal from "sweetalert2"

export const confirmDeleteAlert = (prop) => {
    return Swal.fire({
        title: prop.title,
        showCancelButton: true,
        icon: "warning",
        // imageUrl: "https://cdn-icons-png.flaticon.com/512/6460/6460112.png",
        // imageHeight: 100,
        // imageWidth: 100,
        // imageClass: 'img-fluid',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: prop.confirmText,
        cancelButtonText: prop.cancelText,
        reverseButtons: true,
        customClass: {
            popup: "rounded-4",
            closeButton: 'close-button-class',
            title: "swalTitle",
            confirmButton: 'swal-cancel-btn', //btn reversed so css reversed
            cancelButton: 'swal-confirm-btn',
        },
    }).then((result) => {
        return result
    })
}

export const confirmSubmit = (prop) => {
    return Swal.fire({
        title: prop.title,
        showCancelButton: true,
        icon: "warning",
        // imageUrl: "https://cdn-icons-png.flaticon.com/512/6460/6460112.png",
        // imageHeight: 100,
        // imageWidth: 100,
        // imageClass: 'img-fluid',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: prop.confirmText,
        cancelButtonText: prop.cancelText,
        reverseButtons: true,
        customClass: {
            popup: "rounded-4",
            closeButton: 'close-button-class',
            title: "swalTitle",
            confirmButton: 'swal-cancel-btn', //btn reversed so css reversed
            cancelButton: 'swal-confirm-btn',
        },
    }).then((result) => {
        return result
    })
}

export const textAlert = (prop) => {
    return Swal.fire({
        title: prop.title,
        showCancelButton: true,
        icon: "warning",
        // imageUrl: "https://cdn-icons-png.flaticon.com/512/6460/6460112.png",
        // imageHeight: 100,
        // imageWidth: 100,
        // imageClass: 'img-fluid',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: prop.confirmText,
        cancelButtonText: prop.cancelText,
        reverseButtons: true,
        customClass: {
            popup: "rounded-4",
            closeButton: 'close-button-class',
            title: "swalTitle",
            confirmButton: 'swal-cancel-btn', //btn reversed so css reversed
            cancelButton: 'swal-confirm-btn',
        },
    }).then((result) => {
        return result
    })
}
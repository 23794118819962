import { useEffect } from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import Swal from "sweetalert2";
import { API_CALL } from "../../../api/api_calls_services";
import { API_CONSTANTS, API_METHOD } from "../../../api/apiConstants";
import mailImage from "../../../images/mails.svg";
import emailAlertStyles from "../../../css/emailAlerts.module.css";
import { reSelectStyle } from "../../../css/ReSelectStyles";
import ShowError from "../../global-components/ShowError";
import HeadingHeader from "../../global-components/HeadingHeader";
import { toast } from "react-hot-toast";

function EmailAlertSending() {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [branchManagerMailId, setBranchManagerMailId] = useState();
  useEffect(() => {
    getAllBranchManager();
  }, []);
  const getAllBranchManager = async () => {
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_BRANCH_MANAGER,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);

    if (result.statusCode === 200) {
      let optionForDropDown = result.data.map((user) => ({
        label: user.name,
        value: user.email,
      }));
      setBranchManagerMailId([
        ...optionForDropDown,
        { label: "Vijay", value: "sherothkar24@gmail.com" },
      ]);
    } else if (result.statusCode > 200) {
      Swal.fire("Error", result.message);
    }
  };

  const submitForm = async (data) => {
    setLoading(true);
    let ccMailIds = data.cc.map((item) => item.value);
    const requestBodyParam = JSON.stringify({
      toMail: data.to.value,
      body: data.msg,
      subject: data.subject,
      cc: ccMailIds,
    });

    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.SEND_MAIL,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    if (result.statusCode === 200) {
      reset({
        to: "",
        cc: "",
        subject: "",
        msg: "",
      });
      setLoading(false);
      toast.success("Mail alert sent successfully");
    } else if (result.statusCode > 200) {
      Swal({
        title: "ERROR",
        text: result.message,
        icon: "https://cdn-icons-png.flaticon.com/512/2569/2569174.png",
        className: "error-window",
        button: true,
      });
      setLoading(false);
    }
    // SEND_MAIL
  };
  const imgStyle = {
    objectFit: "contain",
    height: "300px",
  };
  return (
    <>
      <HeadingHeader
        title={"Feedback & Enquiries"}
        subTitle={"Create Alerts"}
      />
      <div className="container pt-4 pb-5">
        <div className="px-lg-4">
          <div className="row ">
            <div className="col-lg-6  mt-auto">
              {/* <Image src={email} /> */}

              <img
                src={mailImage}
                style={imgStyle}
                className="rounded d-block"
              />
            </div>
            <div className="col-lg-6 my-auto ">
              <div
                className={`position-relative px-5   ${emailAlertStyles.header}`}
              >
                <p className="m-0 font-os text-muted fw-600 fz-14px ">
                  Enter your message
                </p>
              </div>

              <div className="card border-0 px-5 pt-4 pb-4">
                <form
                  onSubmit={handleSubmit(submitForm)}
                  className="theme-reSelect"
                >
                  <Controller
                    name="to"
                    control={control}
                    rules={{ required: "Please fill this field" }}
                    render={({ field }) => (
                      <Select
                        styles={reSelectStyle}
                        {...field}
                        options={branchManagerMailId}
                        isClearable={true}
                        placeholder="To:"
                        isSearchable={true}
                      />
                    )}
                  />
                  {errors?.to && <ShowError text={errors.to?.message} />}

                  <div className="py-2"></div>

                  <Controller
                    name="cc"
                    control={control}
                    rules={{ required: "Please fill this field" }}
                    render={({ field }) => (
                      <Select
                        styles={reSelectStyle}
                        {...field}
                        options={branchManagerMailId}
                        isClearable={true}
                        placeholder="CC:"
                        closeMenuOnSelect={false}
                        isMulti
                        isSearchable={true}
                      />
                    )}
                  />
                  {errors?.cc && <ShowError text={errors.cc?.message} />}

                  <div className="py-2"></div>
                  <input
                    placeholder="Subject"
                    className="rounded default-input py-2"
                    {...register("subject", {
                      required: "Please fill this field",
                    })}
                  />
                  {errors?.subject && (
                    <ShowError text={errors.subject?.message} />
                  )}
                  <div className="py-2"></div>
                  <textarea
                    placeholder="Add Message"
                    className="rounded default-input pt-3"
                    rows={"10"}
                    {...register("msg", { required: "Please fill this field" })}
                  />
                  {errors?.msg && <ShowError text={errors.msg?.message} />}
                  <div className="py-2"></div>
                  <div className="text-end">
                    <button
                      type="submit"
                      className="bgTomato text-white border-0 py-2 px-5 rounded-1"
                      disabled={loading ? true : false}
                    >
                      {loading ? "Please wait..." : "Send"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmailAlertSending;

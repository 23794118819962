import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useRecoilState, useRecoilValue } from 'recoil';
import { API_CONSTANTS, API_METHOD } from '../../../../api/apiConstants';
import { API_CALL } from '../../../../api/api_calls_services';
import { trackGetAllCheckListStateChange } from '../../../../recoil_states/getchecklist';
import { checkListDataRecoilState } from '../../../../recoil_states/taskEditAtoms';
import JsonViewer from '../../../global-components/JsonViewer';

function CheckListForm() {
    const [checkListDataAtom, setCheckListDataAtom] = useRecoilState(checkListDataRecoilState)
    const { register, formState: { errors }, handleSubmit, reset } = useForm({
        defaultValues: {
            checklistMainTitle: checkListDataAtom?.title,
            taskType: checkListDataAtom?.taskType,
            checklistMainTitleDescription: checkListDataAtom?.description
        }
    });
    const [loading, setLoading] = useState(false);
    const [checkListState, setCheckListState] = useRecoilState(trackGetAllCheckListStateChange);
    const cancelEdit = () => {
        setCheckListDataAtom("");
        reset(
            {
                checklistMainTitle: "",
                taskType: "",
                checklistMainTitleDescription: ""
            }
        )
    }
    const onSubmit = async (data) => {
        if (checkListDataAtom?.title) {
            //Update Checklist API call
            setLoading(true);
            const requestBodyParam = JSON.stringify({
                title: data.checklistMainTitle,
                description: data.checklistMainTitleDescription,
                taskType: data.taskType.toUpperCase(),
                taskState: checkListDataAtom?.taskState
            })
            const request = {
                apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.UPDATE_CHECK_LIST + `/${checkListDataAtom?.id}`,
                method: API_METHOD.PUT,
                requestBodyParams: requestBodyParam,
            }
            let result = await API_CALL(request)
            if (result.statusCode === 200) {
                setLoading(false);
                cancelEdit();
                setCheckListState("change ocuur!from Form" + Math.random());
                toast.success('Checklist was updated successfully!')

            }
        }
        else {
            //New Checklist Creation API call
            setLoading(true);
            const requestBodyParam = JSON.stringify({
                title: data.checklistMainTitle,
                description: data.checklistMainTitleDescription,
                taskType: data.taskType.toUpperCase(),
                taskState: "ACTIVE"
            })
            const request = {
                apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.CREATE_CHECK_LIST,
                method: API_METHOD.POST,
                requestBodyParams: requestBodyParam,
            }
            let result = await API_CALL(request)
            if (result.statusCode === 200) {
                setLoading(false);
                reset();
                setCheckListState("change ocuur!from Form" + data.checklistMainTitle);
                toast.success('Checklist was created successfully!')
            }
        }
    }


    return (

        <>
            <JsonViewer data={checkListDataAtom} />
            <form onSubmit={handleSubmit(onSubmit)} >

                <div className='container py-3 checklist-form'>
                    <div className='row'>
                        <div className='col'>
                            <input {...register("checklistMainTitle", { required: true })} placeholder="Enter the Checklist Main Title" />
                            <div className='warn'>{errors.checklistMainTitle?.type === 'required' && "Please Fill the field "}</div>
                        </div>
                        <div className='col'>
                            <select {...register("taskType", { required: true })}>
                                <option value="">Select the type of Task</option>
                                <option value="DAILYTASK">Daily</option>
                                <option value="WEEKLYTASK">Weekly</option>
                                <option value="MONTHLYTASK">Monthly</option>
                            </select>
                            <div className='warn'>{errors.taskType && "Please Fill the field "}</div>
                        </div>
                        <div className='col'>
                            <textarea {...register("checklistMainTitleDescription", { required: true })} placeholder="Enter the Description" />
                            <div className='warn'>{errors.checklistMainTitleDescription?.type === 'required' && "Please Fill the field "}</div>

                        </div>
                        {/* <div className='col'>
                            <select {...register("activeState", { required: true })}>
                                <option value="">Select Task State</option>
                                <option value="ACTIVE">Active</option>
                                <option value="INACTIVE">Inactive</option>
                            </select>
                            <div className='warn'>{errors.activeState && "Please Fill the field "}</div>
                        </div> */}
                    </div>
                </div>
                <footer className='bg-prime py-2'>
                    <div className='container'>
                        <div className='d-flex justify-content-end'>

                            {checkListDataAtom?.title ? <input type="button" onClick={cancelEdit} value="Cancel" className='reset-btn' /> : <input type="button" onClick={() => { reset() }} value="Reset" className='reset-btn' />}
                            {checkListDataAtom?.title ? <input type="submit" className='listsubmit-btn' disabled={loading ? true : false} value={loading ? "Please Wait..." : "Save"} /> : <input type="submit" className='listsubmit-btn' disabled={loading ? true : false} value={loading ? "Please Wait..." : "submit"} />}


                        </div>
                    </div>
                </footer>
            </form>
        </>

    )
}

export default CheckListForm;
export const API_CONSTANTS = {
  BASE_URL: "https://iaround.ciarss.com/api/",
  // BASE_URL: "http://103.108.117.101:8787/",
  CREATE_BRAND_END_POINT: "atms/v1/brand/createBrand",
  REGISTER_BRAND_END_POINT: "atms/v1/registerBrand",
  GET_ALL_BRAND: "atms/v1/brand/getAllBrand",
  CREATE_BRANCH_MANAGER_USER: "atms/v1/registerManager",
  CREATE_CHECK_LIST: "createCheckList",
  CREATE_SUB_CHECK_LIST: "createSubCheckList",
  UPDATE_SUB_CHECKLIST: "updateSubCheckList",
  DELETE_SUB_CHECKLIST: "deleteSubCheckList",
  GET_ALL_CHECK_LIST: "getAllCheckList",
  DELETE_CHECK_LIST: "deleteCheckList",
  UPDATE_CHECK_LIST: "updateCheckList",
  SUPER_ADMIN_GET_ALL_BRAND: "atms/v1/brand/getAllBrand",
  UPDATE_BRAND: "atms/v1/brand/updateBrand/",
  GET_BRAND_ADMIN: "atms/v1/brand/getBrandAdmin/2",
  GET_BRANCH_MANAGER: "atms/v1/brand/getBranchManager",
  // GET_ALL_BRANCH_MANAGER: "atms/v1/brand/getBranchManager/3",
  LOGIN: "atms/v1/login",
  DELETE_USER_DETAIL: "atms/v1/deleteUserDetail/",
  DELETE_BRAND: "atms/v1/brand/deleteBrand/",
  UPDATE_USER_DETAIL: "atms/v1/updateUserDetail/",
  SEND_MAIL: "sendMail",
  ASSIGN_TASK: "updateCheckListwithUser",
  GET_ASSIGNED_TASK: "getAssignedTask",
  DELETE_ASSIGNED_TASK: "deleteAssignedtask",
  GET_ASSIGNED_TASK_BY_ID: "getassignedTask",
  GET_DAILY_TASK: "getDailyTask",
  GET_WEEKLY_TASK: "getWeeklyTask",
  GET_MONTHLY_TASK: "getMonthlyTask",
  GET_PENDING_TRACK_TASKS: "getPendingTrackTasks",
  GET_APPROVED_TRACK_TASKS: "getApprovedTrackTasks",
  GET_REJECTED_TRACK_TASKS: "getRejectedTrackTasks",
  BRANCH_CHECK_LIST_UPDATE: "branchCheckListUpdateImage",
  UPDATE_REJECTED_SUB_LIST_IMAGE: "updateRejectsubListImage",
  GET_APPROVE_PENDING_TASK: "getApprovePendingTask",
  GET_APPROVED_TASK: "getApprovedTask",
  GET_REJECTED_TASK: "getRejectedTask",
  APPROVE_SUB_CHECK_LIST: "approveSubcheckList",
  REJECT_SUB_CHECK_LIST: "rejectSubcheckList",
  BRANCH_CHECK_LIST_UPDATE_VIDEO: "branchCheckListUpdateVideo",
  GET_PERFORMANCE: "getPerformance",
};
export const API_METHOD = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  PUT: "PUT",
  PATCH: "PATCH",
};


import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { BsEye, BsFilterRight, BsPlusSquareDotted } from "react-icons/bs";
import { API_CONSTANTS, API_METHOD } from '../../../../api/apiConstants';
import { API_CALL } from '../../../../api/api_calls_services';
import JsonViewer from '../../../global-components/JsonViewer';
import LoaderWrapper from '../../../global-components/LoaderWrapper';
import moment from 'moment';

function RejectedTaskList() {
  const [allTaskData, setAllTaskData] = useState(null);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [taskFilter, setTaskFilter] = useState("DAILYTASK");
  useEffect(() => {
    setAllTaskData(null)
    getAllAssignedApprovePendingTask();
  }, [date, taskFilter])

  const getAllAssignedApprovePendingTask = async () => {
    const date = moment().format("YYYY-MM-DD")
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_REJECTED_TASK + `/${date}` + `/${taskFilter}`,
      method: API_METHOD.GET,
    }
    let result = await API_CALL(request)
    if (result.statusCode === 200) {
      setAllTaskData(result.data)

    }
  }
  const renderedData = <LoaderWrapper data={allTaskData} noDataMsg={<h6 className='text-center text-muted'>No task present</h6>} >
    <div className='sub-checklist'>
      <table className="table-cust" >
        <thead>
          <tr>
            <th className='w-50'>
              Users
            </th>
            <th>
              Expand
            </th>
          </tr>
        </thead>
      </table >
      <Accordion defaultActiveKey={0} flush>
        {allTaskData?.map((item, index) => (
          <Accordion.Item eventKey={index} key={item.userId} className="py-1">
            <Accordion.Header><h6 className=" text-capitalize">User Name: <b>{item.userDetail.userName}</b> </h6> </Accordion.Header>
            <Accordion.Body>
              {item.checkList.map((checkItem, cIndx) => (
                <div key={checkItem.id}  >
                  <h5 className='fz-12px'>{cIndx + 1}. Check List:  {checkItem.title} </h5>
                  <h6 className='my-3'><b>Description</b>: {checkItem.description}</h6>
                  <span className='text-white px-3 py-1 rounded-4 fw-600 font-os fz-13px bg-secondary mb-3'>Sub Checklist / {checkItem?.taskType == "DAILYTASK" ? "Daily Task" : ""} {checkItem?.taskType == "WEEKLYTASK" ? "Weekly Task" : ""} {checkItem?.taskType == "MONTHLYTASK" ? "Monthly Task" : ""}</span>

                  <table className="table-cust">
                    <thead>
                      <tr>
                        <th>
                          SNo
                        </th>
                        <th>
                          Title
                        </th>
                        <th>
                          Description
                        </th>
                        <th className='w-25'>
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody  >
                      {
                        checkItem.subCheckList == null ?
                          <tr>
                            <td colSpan={5} className="text-muted">
                              No SubCheckList Available
                            </td>
                          </tr> :
                          checkItem.subCheckList.map((subItem, indx) => (
                            <tr key={subItem.sub_id}>
                              <td>
                                {(indx + 1).toString().padStart(2, '0')}
                              </td>
                              <td >
                                {subItem.title}
                              </td>
                              <td >
                                {subItem.description}
                              </td>
                              {subItem.imageLocation || subItem?.videoLocation ?
                                <td >
                                  <div className="d-flex justify-content-around">

                                    <div className='REJECTED'>REJECTED</div>

                                  </div>
                                </td> :
                                <td role={"button"} >
                                  <div className="d-flex justify-content-around text-danger">
                                    Not Completed
                                  </div>
                                </td>
                              }

                            </tr>
                          ))
                      }
                    </tbody>
                  </table>
                  <div className="my-3"></div>
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div >
  </LoaderWrapper >
  return (
    <>
      <div>

        <div className="pt-2 pb-4">
          <BsFilterRight /> <span className=' font-os fw-500 fz-14px text-dark'>Filter by date : </span> <input type='date' className='date' value={date} onChange={(e) => setDate(e.target.value)} />
          <span className='ms-2 font-os fw-500 fz-14px text-dark'>Task type: </span>
          <select type='date' className='date pe-5' value={taskFilter} onChange={(e) => setTaskFilter(e.target.value)}  >
            <option value={"DAILYTASK"}>Daily</option>
            <option value={"WEEKLYTASK"}>Weekly</option>
            <option value={"MONTHLYTASK"}>Monthly</option>
          </select>
        </div>

        {renderedData}
      </div>
      <JsonViewer data={allTaskData} />
      <div className="py-4"></div>

    </>
  )
}

export default RejectedTaskList

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import MenBarComponent from "./menu";
import SideBarComponent from "./sidebar";
import FooterComponents from "./footer";

export default function LayoutWrapper({
  componentIdentifier = "Default_Dashboard_Layout",
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const location = useLocation();

  switch (componentIdentifier) {
    case "Default_Dashboard_Layout": //we are changing to default layout if componentIdentifier passed with nothing
      return (
        <div className="container-fluid">
          <div className="row">
            {/* this is already has column inside component */}
            <SideBarComponent isOpen={isOpen} />
            {/* col-lg-2 end */}
            <div className="col p-0">
              <MenBarComponent toggle={toggle} isOpen={isOpen} />
              {props.children}
            </div>
            <FooterComponents />
          </div>
        </div>
      );
    case "PRODUCT_MANAGER":
      return (
        <div className="container-fluid">
          <div className="row">
            {/* this is already has column inside component */}
            <SideBarComponent isOpen={isOpen} />
            {/* col-lg-2 end */}
            <div className="col p-0">
              <MenBarComponent toggle={toggle} isOpen={isOpen} />
              {props.children}
            </div>
            <FooterComponents />
          </div>
        </div>
      );
    case "BRAND_ADMIN":
      return (
        <div className="container-fluid">
          <div className="row">
            {/* this is already has column inside component */}
            <SideBarComponent isOpen={isOpen} />
            {/* col-lg-2 end */}
            <div className="col p-0">
              <MenBarComponent toggle={toggle} isOpen={isOpen} />

              {props.children}
            </div>
            <FooterComponents isOpen={isOpen} />
          </div>
        </div>
      );
    default:
      return (
        <div> {props.children}</div> //take for login layout
      );
  }
}

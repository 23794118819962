import React from 'react'

function JsonViewer({ data }) {
    return (
        <>

            {/* <pre>{JSON.stringify(data, null, 4)}</pre> */}

        </>

    )
}

export default JsonViewer
import React from 'react'
import { useTable } from 'react-table'
import { FaRegTrashAlt } from 'react-icons/fa'
import { MdOutlineSkipPrevious } from 'react-icons/md'
import { MdOutlineSkipNext } from 'react-icons/md'
import { IoIosArrowBack } from 'react-icons/io'
import { IoIosArrowForward } from 'react-icons/io'
import { FiEdit } from 'react-icons/fi'
import { AiOutlineEye } from 'react-icons/ai'
import { API_CALL } from '../../api/api_calls_services'
import { API_CONSTANTS, API_METHOD } from '../../api/apiConstants'
import { useState } from 'react'
import { useRecoilState } from 'recoil'
import { refetchAllBrandAdminUsersAtomState } from '../../recoil_states/refetchAllBrandAdminUsersAtom'
import Swal from 'sweetalert2'
import { editBrandUserAtomFunc } from '../../recoil_states/editBrandUserAtom'
import { editBrandAtomFunc } from '../../recoil_states/editBrandAtom'
function RegisterBrandTable() {
  const [getBrandAdmin, setBrandAdmin] = useState([])
  const [getbrandAdminTrigger, setGetbrandAdminTrigger] = useRecoilState(refetchAllBrandAdminUsersAtomState);
  const [editBrandUserAtom, setEditBrandUserAtom] = useRecoilState(editBrandUserAtomFunc);
  const [brandEditAtom, setBrandEditAtom] = useRecoilState(editBrandAtomFunc)


  const [loading, setLoading] = useState(false);
  React.useEffect(() => {
    getAllBrandAdmin();
  }, [getbrandAdminTrigger.RefetchBrandAdminUsers])

  const getAllBrandAdmin = async () => {
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_BRAND_ADMIN,
      method: API_METHOD.GET,
    }
    let result = await API_CALL(request)

    if (result.statusCode === 200) {
      setBrandAdmin(result.data)
      // alert(JSON.stringify(result.data))
    } else if (result.status > 200) {
      alert('errror', result)
    }
  }
  const data = React.useMemo(
    () => {
      const rowData = getBrandAdmin.map((items, i) => ({
        ...items, SNo: i + 1, brandName: items.brandId.brandName
      }
      ));
      return rowData
    },
    [getBrandAdmin]
  )

  const columns = React.useMemo(
    () => [
      {
        Header: 'Brand Name',
        accessor: 'brandName',

      },
      {
        Header: 'User Name',
        accessor: 'name',
      },
      {
        Header: 'Phone No',
        accessor: 'phoneNo',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Address',
        accessor: 'address',
      },
      // {
      //   Header: 'Password',
      //   accessor: 'col6',
      // },
      {
        Header: 'ACTION',
        accessor: 'delete',
        Cell: row => (<div>
          {/* <button className="border-0 bg-transparent  text-primary" onClick={() => alert(JSON.stringify(row.row.original))}><AiOutlineEye /></button> */}
          <button className="border-0 bg-transparent text-success me-2" onClick={() => onEditBrandUser(row.row.original)}><FiEdit size={17} /></button>
          <button className="border-0 bg-transparent  text-danger" onClick={() => onDeleteBrandUser(row.row.original.id)}><FaRegTrashAlt /></button>

        </div>),
      }
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data })

  const onDeleteBrandUser = async (brandId) => {
    setLoading(true);
    // imageUrl: 'https://cdn-icons-png.flaticon.com/512/6460/6460112.png',
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const requestBodyParam = {
          method: 'DELETE',
          redirect: 'follow'
        };

        const request = {
          apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.DELETE_USER_DETAIL + `${brandId}`,
          method: API_METHOD.DELETE,
          requestBodyParams: requestBodyParam,
        }
        let result = await API_CALL(request)
        if (result.statusCode === 200) {
          setLoading(false);
          getAllBrandAdmin();
        } else if (result.status > 200) {
          Swal.fire("Error", result.message);
        }
      }
    })
  }
  const onEditBrandUser = (clickedRowData) => {
    setBrandEditAtom({
      ...brandEditAtom,
      accordionActiveKey: "0",
      accordionRefreshKey: Math.random(),
      refreshTabKey: Math.random(),
      tabActiveKey: "second",
    })
    setEditBrandUserAtom({
      ...editBrandUserAtom,
      ...clickedRowData,
      btnText: "Save"
    })
  }
  return (

    <div className='table-responsive'>

      <h5 className='py-4'>Registered Brand User Details</h5>

      <table {...getTableProps()} className='table-cust'>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()} >
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}

                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}

                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="py-4"></div>
    </div>
  )
}

export default RegisterBrandTable
import React from 'react'
import { useNavigate } from "react-router-dom";
   
function Home() {
  const navigate = useNavigate();
  const logout = (e) => {
    localStorage.removeItem('jwt');
    navigate("/login")
  }

  return (
    <div><h1>Home</h1><button onClick={logout}>Out</button></div>
  )
}

export default Home
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { toast } from 'react-hot-toast';
import { useRecoilState, useResetRecoilState } from 'recoil';
import Swal from 'sweetalert2';
import { API_CONSTANTS, API_METHOD } from '../../api/apiConstants';
import { API_CALL } from '../../api/api_calls_services';
import { editBrandAtomFunc } from '../../recoil_states/editBrandAtom';
import { editBrandUserAtomFunc } from '../../recoil_states/editBrandUserAtom';
import { refetchAllBrandAdminUsersAtomState } from '../../recoil_states/refetchAllBrandAdminUsersAtom';
import { refetchBrandAtomState } from '../../recoil_states/refetchBrandAtom';
function RegisterBrandForm() {
    const [editBrandUserAtom, setEditBrandUserAtom] = useRecoilState(editBrandUserAtomFunc);
    const resetBrandUserAtom = useResetRecoilState(editBrandUserAtomFunc);
    const [brandEditAtom, setBrandEditAtom] = useRecoilState(editBrandAtomFunc)

    const { register, reset, setValue, formState: { errors }, handleSubmit } = useForm({
        defaultValues: {
            brandId: editBrandUserAtom?.brandId?.brandId,
            name: editBrandUserAtom?.name,
            email: editBrandUserAtom?.email,
            address: editBrandUserAtom?.address,
            phoneNo: editBrandUserAtom?.phoneNo,
            password: editBrandUserAtom?.password,
        }
    });
    const [brandNames, setBrandNames] = useState([]);
    const [getbrandAdminTrigger, setGetbrandAdminTrigger] = useRecoilState(refetchAllBrandAdminUsersAtomState)
    const [getBrandTrigger, setGetBrandTrigger] = useRecoilState(refetchBrandAtomState)

    useEffect(() => {
        getAllBrands();
    }, [getBrandTrigger.brandRefetch])
    //  =========API CALL register brand=============
    const onSubmit = (data) => {
        // setLoading(true);
        if (editBrandUserAtom?.name) {
            updateRegisterdUser(data)
        }
        else {
            registerUser(data)
        }
    }
    const updateRegisterdUser = async (data) => {

        let request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.UPDATE_USER_DETAIL + `${editBrandUserAtom?.id}`,
            method: API_METHOD.PUT,
            requestBodyParams: JSON.stringify({
                name: data.name,
                email: data.email,
                address: data.address,
                phoneNo: data.phoneNo,
                password: data.password,
                brandId: data.brandId,
            })
        }
        // alert(request.requestBodyParams)
        let result = await API_CALL(request)
        if (result.statusCode === 200) {
            toast.success("User detail updated successfully")
            cancel();
            setGetbrandAdminTrigger({ "RefetchBrandAdminUsers": Math.random() })
        } else if (result.statusCode > 200) {
            Swal.fire("Error", result.message);
        }
    }
    const registerUser = async (data) => {
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.REGISTER_BRAND_END_POINT,
            method: API_METHOD.POST,
            requestBodyParams: JSON.stringify({
                name: data.name,
                email: data.email,
                address: data.address,
                phoneNo: data.phoneNo,
                password: data.password,
                brandId: data.brandId,
            })
        }
        // alert(JSON.stringify(request));
        let result = await API_CALL(request)
        if (result.statusCode === 200) {
            toast.success("New brand user register successfully")

            setGetbrandAdminTrigger({ "RefetchBrandAdminUsers": Math.random() })
            reset();

        } else if (result.statusCode > 200) {
            // setError(result.message)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: result.message
            })
        }
    }
    //  =========API CALL End=============

    const getAllBrands = async () => {
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_ALL_BRAND,
            method: API_METHOD.GET,
        }
        let result = await API_CALL(request)


        if (result.statusCode === 200) {
            // alert(JSON.stringify(result.data[0].courseOverview));
            // reset();
            // alert(JSON.stringify(result));
            setBrandNames(result.data);

        } else if (result.statusCode > 200) {
            Swal.fire('Error', result)

        }
    }
    const cancel = () => {
        resetBrandUserAtom();
        setBrandEditAtom({
            ...brandEditAtom, accordionActiveKey: "",
            accordionRefreshKey: "",
            refreshTabKey: "",
            tabActiveKey: "second",
            btnText: false,
        })
    }
    return (
        <div>

            {/* <pre>{JSON.stringify(brandNames, null, 3)}</pre> */}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='container py-3'>
                    <div className="row register-brand-style">
                        <div className="col-lg-6">
                            <select  {...register("brandId", { required: true })} className="w-100 my-2 p-1">
                                <option value={editBrandUserAtom?.brandId?.brandId || ""} >{editBrandUserAtom?.brandId?.brandId ? editBrandUserAtom?.brandId?.brandName : "Select a Brand name"}</option>
                                {brandNames?.map((names, i) => (
                                    <option key={i} value={names.brandId}>{names.brandName}</option>
                                ))}
                            </select>

                            <small className='text-danger'>{errors.brandId?.type === 'required' && "brand name is required"}</small>
                        </div>
                        <div className="col-lg-6">
                            <input className="w-100 my-2 p-2" type="text" placeholder='Name' {...register("name", { required: true })} />
                            <small className='text-danger'>{errors.name?.type === 'required' && " name is required"}</small>
                        </div>
                        {/* ---- */}
                        <div className="col-lg-6">

                            <input className="w-100 my-2 p-2" type="email" placeholder='email' {...register("email", { required: true })} />
                            <small className='text-danger'>{errors.email && "email is required"}</small>


                        </div>
                        <div className="col-lg-6">
                            <input className="w-100 my-2 p-2" type="text" placeholder='address' {...register("address", { required: true })} />
                            <small className='text-danger'>{errors.address && "address is required"}</small>

                        </div>
                        {/* ------ */}
                        <div className="col-lg-6">
                            <input className="w-100 my-2 p-2" type="number" placeholder='phoneNo' {...register("phoneNo", { required: true })} />
                            <small className='text-danger'>{errors.phoneNo && "phoneNo is required"}</small>

                        </div>
                        <div className="col-lg-6">
                            <input className="w-100 my-2 p-2" type="password" placeholder='password'  {...register("password", { required: true })} />
                            <small className='text-danger'>{errors.password && "password is required"}</small>
                        </div>
                    </div>
                </div>
                <footer>
                    <div className='container'>
                        <div className='d-flex justify-content-end'>

                            <input type="submit" className='listsubmit-btn' value={editBrandUserAtom.btnText ? editBrandUserAtom.btnText : "Register"} />
                            {editBrandUserAtom.btnText ? <button type='button' className='reset-btn' role="button" onClick={cancel}>Cancel</button> : <input type="reset" value="Reset" className='reset-btn' />}

                        </div>
                    </div>
                </footer>
            </form>
        </div>
    )
}

export default RegisterBrandForm
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Select from 'react-select';
import { useRecoilState } from 'recoil';
import Swal from 'sweetalert2';
import { API_CONSTANTS, API_METHOD } from '../../../../api/apiConstants';
import { API_CALL } from '../../../../api/api_calls_services';
import { assignedTaskDataRecoilState } from '../../../../recoil_states/taskEditAtoms';
import JsonViewer from '../../../global-components/JsonViewer';
import ShowError from '../../../global-components/ShowError';

function AssignTask() {

    const { register, handleSubmit, formState: { errors }, reset, control } = useForm();
    const [AssignedTaskDataAtom, setAssignedTaskDataAtom] = useRecoilState(assignedTaskDataRecoilState)
    const [checklistOptions, setChecklistOptions] = useState([])
    const [userList, setUserList] = useState([])
    const [loading, setLoading] = useState(false)
    const resetAll = () => {
        reset({
            assignTo: '',
            assignTask: ''
        });
    }
    const submitForm = async (data) => {
        const taskIdList = data.assignTask.map((items) => (
            items?.value
        ))
        const userIdList = data.assignTo.map((items) => (
            items?.value
        ))
        const requestBodyParam = JSON.stringify({
            "checkList": taskIdList,
            "userDetail": userIdList
        })
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.ASSIGN_TASK,
            method: API_METHOD.POST,
            requestBodyParams: requestBodyParam,
        }
        let result = await API_CALL(request)
        if (result.statusCode === 200) {
            toast.success("Task assigned successfully")
        } else if (result.statusCode > 200) {
            console.log(result)
        }
        // console.log(errors);
        // console.log(taskIdList);
        // console.log(userIdList);
        resetAll();
    };

    const getAllBrachManagers = async () => {
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_BRANCH_MANAGER,
            method: API_METHOD.GET,
        }
        let result = await API_CALL(request)
        if (result.statusCode === 200) {
            const res = result.data
            let userData = res.map((item, index, array) => (
                { label: item?.name, value: item?.id }
            ));
            setUserList(userData)

        } else if (result.statusCode > 200) {
            Swal.fire('errror', result)
        }
    }

    const getAllCheckList = async () => {
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_ALL_CHECK_LIST,
            method: API_METHOD.GET,
        }
        let result = await API_CALL(request)

        if (result.statusCode === 200) {
            const res = result.message
            let checkListData = res.map((item, index, array) => (
                { label: item?.title, value: item?.id }
            ));
            setChecklistOptions(checkListData);

        } else if (result.statusCode > 200) {
            console.log('errror', result)
        }
    }
    useEffect(() => {
        (async () => {
            const getAllCheckListRes = await getAllCheckList();
            const getAllBrachManagersRes = await getAllBrachManagers();
            let defaultValues = {};

            if (AssignedTaskDataAtom) {
                defaultValues.assignTask = { value: AssignedTaskDataAtom?.checkList[0]?.id, label: AssignedTaskDataAtom?.checkList[0]?.title };
                defaultValues.assignTo = { value: AssignedTaskDataAtom?.userDetail?.id, label: AssignedTaskDataAtom?.userDetail?.userName };
                reset({ ...defaultValues });
            }
        })();
    }, []);
    const cancelEdit = () => {
        setAssignedTaskDataAtom("");
        reset(
            {
                assignTask: "",
                assignTo: "",
            }
        )
    }
    return (
        <>

            <JsonViewer data={userList} />

            <form onSubmit={handleSubmit(submitForm)}>
                <div className='container py-3'>

                    <div className='row'>

                        <div className='col'>
                            <Controller
                                name="assignTask"
                                control={control}
                                rules={{ required: "Please fill this field" }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        // defaultValue={[options[2]]}
                                        options={checklistOptions}
                                        isClearable={true}
                                        placeholder="Task"
                                        closeMenuOnSelect={false}
                                        isMulti
                                        isSearchable={true}
                                    />
                                )}
                            />
                            {errors?.assignTask && <ShowError text={errors.assignTask?.message} />}

                        </div>
                        <div className='col'>
                            <Controller
                                name="assignTo"
                                isMulti
                                control={control}
                                rules={{ required: "Please fill this field" }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={userList}
                                        isClearable={true}
                                        placeholder="Select users"
                                        closeMenuOnSelect={false}
                                        isMulti
                                        isSearchable={true}
                                    />
                                )}
                            />
                            {errors?.assignTo && <ShowError text={errors.assignTo?.message} />}
                        </div>
                    </div>

                </div>
                <footer className='bg-prime py-2'>
                    <div className='container'>
                        <div className='d-flex justify-content-end'>


                            {AssignedTaskDataAtom ? <input type="button" onClick={cancelEdit} value="Cancel" className='reset-btn' /> : <input type="button" onClick={() => { reset() }} value="Reset" className='reset-btn' />}
                            {AssignedTaskDataAtom ? <input type="submit" className='listsubmit-btn' disabled={loading ? true : false} value={loading ? "Please Wait..." : "Save"} /> : <input type="submit" className='listsubmit-btn' disabled={loading ? true : false} value={loading ? "Please Wait..." : "Assign"} />}


                        </div>
                    </div>

                </footer>
                <JsonViewer data={AssignedTaskDataAtom} />
            </form>


        </>
    )
}

export default AssignTask
import React from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import HeadingHeader from '../global-components/HeadingHeader';
import BranchManagerDaily from './task_table/branch-manager-daily';
import BranchManagerMonthly from './task_table/branch-manager-monthly';
import BranchManagerTaskTable from './task_table/branch-manager-task-table';
import BranchManagerWeekly from './task_table/branch-manager-weekly';

function BranchManagerTask() {

  return (
    <>
      <HeadingHeader title={"Assigned Task"} subTitle={"Task"} />
      <div className="container-fluid" id="branch_task" >
        <div className="py-3"></div>
        <Tab.Container defaultActiveKey="second" mountOnEnter unmountOnExit>
          <Row >
            <Col sm={12}>
              <Nav variant="pills" className=" justify-content-center ">
                {/* <Nav.Item>
                  <Nav.Link eventKey="first">ALL</Nav.Link>
                </Nav.Item> */}
                <Nav.Item>
                  <Nav.Link eventKey="second">DAILY</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">WEEKLY</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="four">MONTHLY</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <div className="py-3"></div>
            <Col sm={12}>
              <Tab.Content>
                {/* <Tab.Pane eventKey="first">
                  <BranchManagerTaskTable />
                </Tab.Pane> */}
                <Tab.Pane eventKey="second">
                  <BranchManagerDaily />
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <BranchManagerWeekly />
                </Tab.Pane>
                <Tab.Pane eventKey="four">
                  <BranchManagerMonthly />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>

      </div>
    </>
  )
}

export default BranchManagerTask


import { atom } from "recoil";

export const liveImageCaptureAtom = atom({
    key: 'a1d22346-57ec-4863-ac4a-ba5e172e237c', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
});
export const onEditBrandUserAtom = atom({
    key: '1f376dba-0976-4838-bd03-4eeb09d60a17', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
});

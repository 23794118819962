import { atom } from "recoil";

export const accordionDefaultActiveKeyRecoilState = atom({
    key: 'a87b4fe2-72be-40e2-9fd4-029ee79ffe46', // unique ID (with respect to other atoms/selectors)
    default: {
        "accordionActiveKey": "",
        "triggerReRender": "",
        "tabActiveKey": "Checklist"
    },
});

export const checkListDataRecoilState = atom({
    key: '268f1337-f6c3-454e-8d59-e4e99346dac9', // unique ID (with respect to other atoms/selectors)
    default: "",
});
export const subCheckListDataRecoilState = atom({
    key: '2787d31c-b411-45f1-a657-621c15689f38', // unique ID (with respect to other atoms/selectors)
    default: "",
});
export const assignedTaskDataRecoilState = atom({
    key: '23ae05d5-8ce1-4c78-b195-e40d21585ac1', // unique ID (with respect to other atoms/selectors)
    default: "",
});


import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { BsPlusLg, BsEye, BsPlusSquareDotted } from "react-icons/bs";
import { useRecoilState } from 'recoil';
import { API_CONSTANTS, API_METHOD } from '../../../api/apiConstants';
import { API_CALL } from '../../../api/api_calls_services';
import { getLoggedInUserDetail } from '../../../api/loggedInUserDetail';
import { liveImageCaptureAtom } from '../../../recoil_states/AtomsStore';
import JsonViewer from '../../global-components/JsonViewer';
import BootstrapModal from '../../global-components/utils/BootsrapModal';
import { confirmSubmit } from '../../global-components/utils/swalAlerts';
import LivePhotoTask from './livePhoto/LivePhotoTask';

function BranchManagerTaskTable() {
  const [showViewModal, setShowViewModal] = useState(false);
  const [modalSubTaskData, setModalSubTaskData] = useState(false);
  const [isLivePhotoOn, setIsLivePhoto] = useState(false);
  const [allTaskData, setAllTaskData] = useState()
  const [isReadOnly, setIsReadOnly] = useState(false)
  const [comment, setComment] = useState("No comments")
  const [imgRecoilState, setImgRecoilState] = useRecoilState(liveImageCaptureAtom)
  useEffect(() => {
    getAllAssignedTask();
  }, [])

  const getAllAssignedTask = async () => {
    const userData = getLoggedInUserDetail;
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_ASSIGNED_TASK_BY_ID,
      method: API_METHOD.GET,
    }
    let result = await API_CALL(request)
    if (result.statusCode === 200) {
      setAllTaskData(result.data)

    }
  }
  const onAction = (isReadOnly, checkListData, subCheckList, indx) => {
    setModalSubTaskData({ checkListId: checkListData.id, ...subCheckList, indx })
    setIsLivePhoto(false)
    setImgRecoilState(null)
    setShowViewModal(true)
    if (isReadOnly) {
      setIsReadOnly(true)
    } else {
      setIsReadOnly(false)

    }
  }

  const onUpload = async () => {

    const isClicked = await confirmSubmit({ title: "Are you sure to upload?", cancelText: "No", confirmText: "Yes" });
    if (isClicked.isConfirmed) {
      const requestBodyParam = JSON.stringify({
        "comments": comment,
        "checkList": modalSubTaskData?.checkListId,
        "subCheckList": modalSubTaskData?.id,
        "base64": imgRecoilState?.substring("data:image/jpeg;base64,".length)

      })
      const request = {
        apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.BRANCH_CHECK_LIST_UPDATE,
        method: API_METHOD.POST,
        requestBodyParams: requestBodyParam,
      }
      let result = await API_CALL(request)
      if (result.statusCode === 200) {
        toast.success("Uploaded")
        setIsLivePhoto(false)
        setComment("")
        setImgRecoilState(null)
        setShowViewModal(false)
      }
    }
  }

  return (
    <div className='container-fluid '>
      <BootstrapModal
        fullscreen={false}
        isShowModal={showViewModal}
        setIsModal={setShowViewModal}
        title={
          <h5 className={``}>Task Detail</h5>
        }
        body={
          <div >
            <h5 className='text-capitalize'>{modalSubTaskData.indx}. {modalSubTaskData?.title}</h5>
            <p className='my-2'><b>Description:</b> {modalSubTaskData?.description}</p>

            <JsonViewer data={modalSubTaskData} />
            {isReadOnly ? <div>
              <b className='  d-block'>Uploaded image: </b>

              <img src={modalSubTaskData?.image_location} className="w-100 d-block" />
            </div>
              : <div className="d-flex align-items-center">
                <b>Upload now: </b>
                {isLivePhotoOn ? "" : <button type="button" className='border-0 bg-transparent' onClick={() => setIsLivePhoto(true)}><BsPlusLg /></button>}
              </div>
            }

            {isLivePhotoOn ? <><LivePhotoTask /> <textarea type="text" placeholder='Add comments' className='py-2 default-input w-100' cols={"10"} onChange={e => setComment(e.target.value)} /> </> : ""}

            <div className='text-end'>
              {imgRecoilState ? <button className={"border-0 bg-success fz-14px fw-500 text-white px-3 py-1 rounded-pill mb-2 mx-2"} onClick={onUpload}>Upload</button>

                : ""}
              {isLivePhotoOn ? <button onClick={() => { setIsLivePhoto(false); setImgRecoilState(null) }} className={"border-0 bgTomato fz-14px fw-500 text-white px-3 py-1 rounded-pill"}> Cancel</button> : ""}

            </div>
          </div>
        }
      />

      <div >
        {
          allTaskData ?
            <div className='sub-checklist'>

              < table className="table-cust" >
                <thead>
                  <tr>
                    <th className='w-50'>
                      CheckList
                    </th>
                    <th>
                      Expand
                    </th>
                  </tr>

                </thead>
              </table >
              <Accordion defaultActiveKey={0} flush>{
                allTaskData.map((assignTaskDataItems, mainIndex) => (
                  assignTaskDataItems?.checkList?.map((checkListData, checkListIndex) => (
                    <Accordion.Item eventKey={checkListIndex} key={checkListData.id + checkListIndex} className="py-1">
                      <Accordion.Header><span className=' text-muted fz-14px'>  {checkListIndex + 1}.</span> <span className='text-dark mx-1 text-capitalize '>{checkListData?.title}</span></Accordion.Header>
                      <Accordion.Body>
                        <div className="px-3">
                          <h6 className='text-muted'>Sub checklist:</h6>

                          <table className="table-cust">
                            <thead>

                              <tr>
                                <th>
                                  SNo
                                </th>
                                <th>
                                  Title
                                </th>
                                <th>
                                  Description
                                </th>
                                {/* <th>
                                TaskState
                              </th> */}
                                <th>
                                  Action
                                </th>
                                {/* <th>
                                  Status
                                </th> */}
                              </tr>

                            </thead>
                            <tbody >

                              {checkListData?.subCheckList?.length ?
                                checkListData?.subCheckList?.map((subCheckList, indx) => (
                                  <tr key={subCheckList?.id + indx}>
                                    <td>
                                      {(indx + 1).toString().padStart(2, '0')}
                                    </td>
                                    <td >
                                      {subCheckList.title}

                                    </td>
                                    <td >
                                      {subCheckList.description}

                                    </td>
                                    {/* <td >
                                    {subCheckList.taskState}

                                  </td> */}
                                    {subCheckList?.status == "APPROVED" || subCheckList?.status == "APPROVE_PENDING" ? <td >
                                      <button className=' p-0 m-0 bg-transparent border-0' onClick={() => onAction(true, checkListData, subCheckList, (indx + 1).toString().padStart(2, '0'))} >
                                        <BsEye />
                                      </button>

                                    </td> :
                                      <td role={"button"} onClick={() => onAction(false, checkListData, subCheckList, (indx + 1).toString().padStart(2, '0'))}>
                                        <BsPlusSquareDotted />
                                      </td>
                                    }
                                    {/* <td className={`${subCheckList?.status}`}>

                                      {subCheckList?.status}
                                    </td> */}
                                  </tr>
                                ))
                                : <tr>
                                  <td colSpan={5} className="text-muted">
                                    No SubCheckList Available
                                  </td>

                                </tr>
                              }
                            </tbody>
                          </table>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                  ))
                ))
              } </Accordion>
            </div > : ""}

      </div>
      <JsonViewer data={allTaskData} />

    </div >
  )
}

export default BranchManagerTaskTable

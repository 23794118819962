import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { FaRegTrashAlt } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'
import { useRecoilState } from 'recoil'
import Swal from 'sweetalert2'
import { API_CONSTANTS, API_METHOD } from '../../../api/apiConstants'
import { API_CALL } from '../../../api/api_calls_services'
import { onEditBrandUserAtom } from '../../../recoil_states/AtomsStore'
import JsonViewer from '../../global-components/JsonViewer'
import LoaderWrapper from '../../global-components/LoaderWrapper'
import Loader from '../../global-components/LoaderWrapper'
import { confirmDeleteAlert } from '../../global-components/utils/swalAlerts'
function CreatedUserTable({ triggerGetAllBrachManagers }) {
  const [getBranchManagersState, setGetBranchManagersState] = useState(null)
  const [onEditBrandUserDataState, setOnEditBrandUserDataState] = useRecoilState(onEditBrandUserAtom)

  React.useEffect(() => {
    getAllBrachManagers();
  }, [triggerGetAllBrachManagers])

  const getAllBrachManagers = async () => {
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_BRANCH_MANAGER,
      method: API_METHOD.GET,
    }
    let result = await API_CALL(request)
    if (result.statusCode === 200) {
      setGetBranchManagersState(result.data)

    } else if (result.status > 200) {
      Swal.fire('errror', result)
    }
  }
  const onDeleteUser = async (data) => {
    const isClicked = await confirmDeleteAlert({ title: "Are you sure want to delete this user?", cancelText: "No", confirmText: "Yes" });
    if (isClicked.isConfirmed) {
      const requestBodyParam = {
        method: 'DELETE',
        redirect: 'follow'
      };
      const request = {
        apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.DELETE_USER_DETAIL + `${data?.id}`,
        method: API_METHOD.DELETE,
        requestBodyParams: requestBodyParam,
      }
      let result = await API_CALL(request)
      if (result.statusCode === 200) {
        toast.success("User deleted successfully");
        getAllBrachManagers();
      }
    }
  }

  const onEdit = (data) => {
    setOnEditBrandUserDataState(data);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  return (

    <div className='table-responsive'>

      <h5 className='py-4'>Registered Branch User Details</h5>


      {/* {!getBranchManagersState ? <Loader/>:currentItems.length ? */}
      <LoaderWrapper noDataMsg={"No data"} data={getBranchManagersState}>

        <div className="table-responsive">
          <table className='table-cust'>

            <tbody>
              <tr>
                <th>Sno </th>
                <th>BrandName</th>
                <th>Name</th>
                <th>Email</th>
                <th>PhoneNo</th>
                <th>Address</th>
                <th>Action</th>
              </tr>
              {getBranchManagersState?.map((buData, index, array) => (
                <tr key={buData?.id}>
                  <td>{index + 1}</td>
                  <td>{buData?.brand?.brandName}</td>
                  <td>{buData?.name}</td>
                  <td>{buData?.email}</td>
                  <td>{buData?.phoneNo}</td>
                  <td>{buData?.address}</td>
                  <td>
                    <button className="border-0 bg-transparent text-success me-2" onClick={() => onEdit(buData)}><FiEdit size={17} /></button>
                    <button className="border-0 bg-transparent  text-danger" onClick={() => onDeleteUser(buData)}><FaRegTrashAlt /></button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

      </LoaderWrapper >

      <JsonViewer data={getBranchManagersState} />
      <div className="py-4"></div>
    </div >
  )
}

export default CreatedUserTable
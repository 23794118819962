import React, { useEffect, useState } from 'react'

function Loader() {
    const [isTimeOut, setIsTimeOut] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setIsTimeOut(true)
        }, 16000);
    }, [])
    return (
        <div className="text-center">
            {isTimeOut ? <h6 className='text-muted'>Failed to Load!</h6> :
                <div className="spinner-border rounded-circle text-secondary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>}
        </div>
    )
}

export default Loader
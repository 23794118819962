import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import Login from '../Components/login';
export default function PrivateRoutes(props) {
    let isLoggedIn = localStorage.getItem('userDetail') == null ? false : true;
    if (props.login) {
        return (
            <>
                {
                    isLoggedIn ? <Navigate to="/" /> : <Login />
                }
            </>
        )
    } else {

        return (
            <>
                {
                    isLoggedIn ? <Outlet /> : <Navigate to="/login" />
                }
            </>

        )
    }

}

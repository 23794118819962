import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';

function ViewModal({ checkListRowData, show, handleClose }) {
    //rowdata for check undefine or has value ?
    const rowdata = checkListRowData?.subRows?.map((subitem, idx) => (
        subitem.title
    ))

    if (rowdata) {
        return (
            <div>

                {JSON.stringify(checkListRowData)}

                <Modal
                    size="lg"
                    show={show}
                    onHide={handleClose}
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title><>{checkListRowData.title}</></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='table-responsive'>
                            <table className="table-cust" >
                                <tr>
                                    <th>SubChecklist</th>
                                    <th>Description</th>
                                    <th>TaskState</th>
                                    <th>CreateDate</th>
                                </tr>
                                <tbody>
                                    {checkListRowData.subRows.map((subitem, i) => {
                                        return (
                                            subitem.title === "---" ? <tr><td className='text-center' colspan="4">No Subchecklist!</td></tr>
                                                : <tr key={i}>
                                                    <td>{subitem.title}</td>
                                                    <td>{subitem.description}</td>
                                                    <td>{subitem.taskState}</td>
                                                    <td>{subitem.createDate}</td>
                                                </tr>)

                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    } else {
        return (<div>


            <Modal
                size="lg"
                show={show}
                onHide={handleClose}
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title><>{checkListRowData.title}</></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='table-responsive'>
                        <table className="table-cust" >
                            <tr>
                                <th>Title</th>
                                <th>Description</th>
                                <th>TaskState</th>
                                <th>CreateDate</th>
                            </tr>
                            <tbody>
                            <tr>
                                <td>{checkListRowData.title}</td>
                                <td>{checkListRowData.description}</td>
                                <td>{checkListRowData.taskState}</td>
                                <td>{checkListRowData.createDate}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </Modal.Body>
            </Modal>
        </div>)
    }
}
export default ViewModal
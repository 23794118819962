import React, { useEffect, useState } from 'react';
import { BsFilterRight, BsPlusSquareDotted } from "react-icons/bs";
import { API_CONSTANTS, API_METHOD } from '../../../../api/apiConstants';
import { API_CALL } from '../../../../api/api_calls_services';
import moment from 'moment/moment';
import LoaderWrapper from '../../../global-components/LoaderWrapper';
import { Accordion } from 'react-bootstrap';
import JsonViewer from '../../../global-components/JsonViewer';

function RejectedTasks() {
    const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
    const [taskFilter, setTaskFilter] = useState("DAILYTASK");
    const [rejectedTaskList, setRejectedTaskList] = useState(null);

    useEffect(() => {
        setRejectedTaskList(null)
        getAllRejectedTask();
    }, [taskFilter, date])
    const getAllRejectedTask = async () => {
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_REJECTED_TRACK_TASKS + `/${date}` + `/${taskFilter}`,
            method: API_METHOD.GET,
        }
        let result = await API_CALL(request)
        if (result.statusCode === 200) {
            setRejectedTaskList(result.data)
        }
    }

    return (
        <div>

            <BsFilterRight /> <span className=' font-os fw-500 fz-14px text-dark'>Filter by date : </span> <input type='date' className='date' value={date} onChange={(e) => setDate(e.target.value)} />
            <span className='ms-2 font-os fw-500 fz-14px text-dark'>Task type: </span>
            <select type='date' className='date pe-5' value={taskFilter} onChange={(e) => setTaskFilter(e.target.value)}  >
                <option value={"DAILYTASK"}>Daily</option>
                <option value={"WEEKLYTASK"}>Weekly</option>
                <option value={"MONTHLYTASK"}>Monthly</option>
            </select>
            <div className="py-3"></div>
            {/* --------------- */}
            <LoaderWrapper noDataMsg={<h6 className='text-center text-muted'>No task  where are present</h6>} data={rejectedTaskList} >
                <div >
                    {
                        rejectedTaskList ?
                            <div className='sub-checklist'>
                                <table className="table-cust" >
                                    <thead>
                                        <tr>
                                            <th className='w-50'>
                                                CheckList
                                            </th>
                                            <th>
                                                Expand
                                            </th>
                                        </tr>
                                    </thead>
                                </table >
                                <Accordion defaultActiveKey={0} flush>{
                                    rejectedTaskList?.map((assignTaskDataItems, mainIndex) => (
                                        assignTaskDataItems?.checkList?.length == 0 ? <h6 className='text-center text-muted py-4 fw-500'>No  task present</h6> :
                                            assignTaskDataItems?.checkList?.map((checkListData, checkListIndex) => (
                                                <Accordion.Item eventKey={checkListIndex} key={checkListData.id + mainIndex} className="py-1">
                                                    <Accordion.Header><span className=' text-muted fz-14px'>  {checkListIndex + 1}.</span> <span className='text-dark mx-1 text-capitalize '>{checkListData?.title}</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="px-3">
                                                            <h6 className='text-muted'>Sub checklist:</h6>
                                                            <table className="table-cust">
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            SNo
                                                                        </th>
                                                                        <th>
                                                                            Title
                                                                        </th>
                                                                        <th>
                                                                            Description
                                                                        </th>
                                                                        <th>
                                                                            Status
                                                                        </th>
                                                                        <th>
                                                                            Reason
                                                                        </th>


                                                                    </tr>
                                                                </thead>
                                                                <tbody >
                                                                    {checkListData?.subCheckList?.length ?
                                                                        checkListData?.subCheckList?.map((subCheckList, indx) => (
                                                                            <tr key={subCheckList?.sub_id}>
                                                                                <td>
                                                                                    {(indx + 1).toString().padStart(2, '0')}
                                                                                </td>
                                                                                <td >
                                                                                    {subCheckList.title}
                                                                                </td>
                                                                                <td >
                                                                                    {subCheckList.description}
                                                                                </td>

                                                                                <td className='text-danger'>
                                                                                    {subCheckList.status}
                                                                                </td>
                                                                                <td className='text-danger'>
                                                                                    {subCheckList.comments}
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                        : <tr>
                                                                            <td colSpan={5} className="text-muted">
                                                                                No SubCheckList Available
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            ))
                                    ))
                                } </Accordion>
                            </div > : ""}
                </div>
            </LoaderWrapper >
            <JsonViewer data={rejectedTaskList} />
        </div>
    )
}

export default RejectedTasks

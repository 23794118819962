import React, { useState } from "react";
import { Col } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { AiOutlineFileSearch, AiOutlineUserAdd } from "react-icons/ai";
import { BiHomeAlt, BiMenuAltLeft, BiUser } from "react-icons/bi";
import { BsSpeedometer2 } from "react-icons/bs";
import { CgHome } from "react-icons/cg";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdOutlineAddTask, MdImageSearch } from "react-icons/md";
import {
  RiListSettingsLine,
  RiMailAddLine,
  RiVipCrownLine,
} from "react-icons/ri";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
import menuStyles from "../global-components/mobileModuleCss/MobileMenu.module.css";

function SideBarComponent({ isOpen }) {
  const [userRole, setUserRole] = useState(localStorage.getItem("userDetail"));
  // useEffect(() => {
  //     setUserRole();
  // }, [])
  const menuItem = [
    {
      path: "/product-manager",
      name: "Dashboard",
      icon: <BiHomeAlt size={19} />,
      role: "PA",
    },
    {
      path: "/product-manager/register",
      name: "Brand",
      icon: <RiVipCrownLine size={19} />,
      role: "PA",
    },
    // ==== BRANCH MANAGER
    {
      path: "/branch-manager",
      name: "Home",
      icon: <CgHome size={19} />,
      role: "BM",
    },
    {
      path: "/branch-manager/task",
      name: "Task",
      icon: <RiListSettingsLine size={19} />,
      role: "BM",
    },
    {
      path: "/branch-manager/track-status",
      name: "Track Status",
      icon: <AiOutlineFileSearch size={19} />,
      role: "BM",
    },

    {
      path: "/performance",
      name: "Performance",
      icon: <BsSpeedometer2 size={19} />,
      role: "BM",
    },
    // ==== BRANCH MANAGER END
    // ==== BRAND-ADMIN
    {
      path: "/brand-admin",
      name: "Dashboard",
      icon: <CgHome size={19} />,
      role: "BA",
    },
    {
      path: "/brand-admin/create_user",
      name: "Create User",
      icon: <AiOutlineUserAdd size={19} />,
      role: "BA",
    },
    {
      path: "/brand-admin/email",
      name: "Create Alerts",
      icon: <RiMailAddLine size={19} />,
      role: "BA",
    },

    {
      path: "/brand-admin/create-task",
      name: "Create Task",
      icon: <MdOutlineAddTask size={19} />,
      role: "BA",
    },
    {
      path: "/brand-admin/verify-task",
      name: "Task Verification",
      icon: <MdImageSearch size={19} />,
      role: "BA",
    },

    {
      path: "/brand-admin/analytics",
      name: "Performance",
      icon: <BsSpeedometer2 size={19} />,
      role: "BA",
    },
    // ==== BRAND-ADMIN END
  ];
  const userData = JSON.parse(userRole);
  const filterMenu = menuItem.filter((items) => {
    return items.role === userData.role;
  });
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleCloseMobileMenu = () => setShowMobileMenu(false);
  const handleShowMobileMenu = () => setShowMobileMenu(true);
  return (
    <>
      <div id="mobile-sidebar">
        <button
          onClick={handleShowMobileMenu}
          className="bg-transparent border-0 position-absolute z-index-10 mt-3"
        >
          <GiHamburgerMenu color="grey" size={22} />
        </button>

        <Offcanvas
          show={showMobileMenu}
          onHide={handleCloseMobileMenu}
          backdrop="static"
        >
          <Offcanvas.Header closeButton className="bg-grape">
            <Offcanvas.Title>
              {/* <img src={logo} /> */}
              <span className={`${isOpen ? "logoTextMin" : "logoText"}`}>
                iAround
              </span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="bg-grape">
            {/* <p><span className="bg-blue-round"><BiUser size={20} /></span> <span className={`${isOpen}`} >{userData.role}</span></p> */}
            <div>
              <ul className={menuStyles.mobMenu}>
                {filterMenu.map((items, i) => (
                  <li key={i} onClick={() => setShowMobileMenu(false)}>
                    <Link to={items.path}>
                      <span>{items.icon}</span>
                      <span>{items.name}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <Col
        id="desktop-sidebar"
        className={`sidebar-spr-bg p-0 col-lg-1 col-sm-12 toggle ${
          isOpen ? "w-60px" : "w-200px"
        }`}
      >
        <div className={`${isOpen ? "sidebar-spr2 " : "sidebar-spr1"}`}>
          <p>
            {" "}
            <span className={`${isOpen ? "logoTextMin" : "logoText"}`}>
              {`${isOpen ? "iA" : "iAround"}`}
            </span>
          </p>
          <div className="mb-30"></div>
          <div>
            <ul className="list-menu">
              {filterMenu.map((items, i) => (
                <li key={i}>
                  <Link to={items.path}>
                    <span className={isOpen ? "align" : "default fz-15px"}>
                      {items.icon}
                    </span>
                    <span className={isOpen ? "hide" : "no-hide fz-15px"}>
                      {items.name}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Col>
    </>
  );
}

export default SideBarComponent;

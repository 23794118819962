import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { SiAnalogue } from "react-icons/si";
import { useNavigate, useSearchParams } from "react-router-dom";
import { API_CONSTANTS, API_METHOD } from "../api/apiConstants";
import { API_CALL } from "../api/api_calls_services";
import logo from "../images/logo.png";
const logoStyle = {
  height: "auto",
  width: "auto",
  objectFit: "contain",
};
function Login() {
  const navigate = useNavigate();
  const loginForm = useRef({}); //for clear form inputs
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const handleError = (errors) => {
    console.log(errors);
  };
  const [loading, setLoading] = useState(false);
  const [apiErrors, setError] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const param = searchParams.get("param");
    if (param) {
      loginApi(JSON.parse(Buffer.from(param, "base64")));
    }
  }, []);
  const handleRegistration = (inputData) => {
    // if (inputData.email === "manager@gmail") {
    //   const res = JSON.stringify({
    //     "displayName": "Branch Manager",
    //     "role": "BRANCH MANAGER",
    //   });
    //   localStorage.setItem("userDetail", res);
    //   loginForm.current.reset();
    //   navigate('/branch-manager');
    // }
    // else if (inputData.email === "admin1@gmail") {
    //   const res = JSON.stringify({
    //     "displayName": "Master",
    //     "role": "MASTER",
    //   });
    //   localStorage.setItem("userDetail", res);
    //   loginForm.current.reset();
    //   navigate('/product-manager');
    // }
    // else {
    loginApi(inputData);
    // }
  };
  const loginApi = async (inputDataArg) => {
    setLoading(true);
    const requestBodyParam = JSON.stringify({
      email: inputDataArg.email,
      password: inputDataArg.password,
    });
    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.LOGIN,
      method: API_METHOD.POST,
      requestBodyParams: requestBodyParam,
    };
    let result = await API_CALL(request);
    setLoading(false);
    if (result.statusCode === 200) {
      localStorage.setItem("userDetail", JSON.stringify(result));
      loginForm.current.reset();
      if (result?.role == "PA") {
        navigate("/product-manager");
      }
      if (result?.role == "BA") {
        navigate("/brand-admin");
      }
      if (result?.role == "BM") {
        navigate("/branch-manager");
      }
    } else {
      setError(result.message);
    }
  };

  const registerOptions = {
    email: { required: "Username is required" },
    password: {
      required: "Password is required",
      // minLength: {
      //   value: 6,
      //   message: "Password must have at least 8 characters"
      // }
    },
  };

  return (
    <div>
      <Container>
        <Row>
          <Col lg={3}></Col>
          <Col lg={6} className="vh-100 d-flex">
            <div className="bg-spr-form">
              <div className="text-center mb-4">
                {/* <img src={logo} style={logoStyle} /> */}
                <span className="logoText">iAround</span>
              </div>
              <p className="text-center text-white pb-2">
                Please enter your user information.
              </p>
              <form
                onSubmit={handleSubmit(handleRegistration, handleError)}
                ref={loginForm}
              >
                <div>
                  <input
                    placeholder="Enter Your Username"
                    type="text"
                    name="email"
                    {...register("email", registerOptions.email)}
                  />
                  <small className="text-danger">
                    {errors?.email && errors.email.message}
                  </small>
                </div>
                <div>
                  <input
                    placeholder="Enter Your Password"
                    type="password"
                    name="password"
                    {...register("password", registerOptions.password)}
                  />
                  <small className="text-danger">
                    {errors?.password && errors.password.message}
                  </small>
                </div>
                <button
                  className="sb-btn rounded-1"
                  disabled={loading ? true : false}
                >
                  {loading ? "Please Wait..." : "LOGIN"}
                </button>
              </form>
              <small className="text-danger my-3 text-center">
                {apiErrors}
              </small>
            </div>
          </Col>
          <Col lg={3}></Col>
        </Row>
      </Container>
    </div>
  );
}

export default Login;

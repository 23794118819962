import React, { useState } from 'react'
import NotFound404 from '../Components/404NotFound';
import SuperAdminDashboard from '../Components/superadmin/dashboard';

function CheckRole({ ...props }) {
    const [currentRole, setCurrentRole] = useState(JSON.parse(localStorage.getItem('userDetail')));
    if (currentRole.role === "PA") {
        return (
            <>  {props.children}</>
        )
    } else {
        return (<NotFound404 />)
    }
}

export default CheckRole
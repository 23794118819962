import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import Swal from 'sweetalert2';
import { API_CONSTANTS, API_METHOD } from '../../../api/apiConstants';
import { API_CALL } from '../../../api/api_calls_services';
import ShowError from '../../global-components/ShowError';
import JsonViewer from '../../global-components/JsonViewer';
function BrandManagerPerformance() {
    const [userList, setUserList] = useState([])
    const [chartData, setChartData] = useState(null)
    const { register, handleSubmit, formState: { errors }, reset, control } = useForm();
    const [loading, setLoading] = useState(false)


    const submitForm = async (data) => {
        // console.log(data);
        setLoading(true)
        const requestBodyParam = JSON.stringify({
            "id": JSON.parse(localStorage.getItem("userDetail"))?.userId,
            "fromDate": data?.fromDate,
            "toDate": data?.toDate
        })
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_PERFORMANCE,
            method: API_METHOD.POST,
            requestBodyParams: requestBodyParam,
        }
        let result = await API_CALL(request)
        if (result.statusCode === 200) {
            toast.success("Performance chart generated");
            setChartData(result.data);
            setChartData([
                {
                    name: 'user',
                    Approved_Task: Math.ceil(result.data.approvedPercentage),
                    Rejected_Task: Math.ceil(result.data.rejectedPercentage),
                    Approve_Pending_Task: Math.ceil(result.data.pendingPercentage),
                    Incomplete_Task: Math.floor(result.data.inCompletePercentage),
                    amt: 100,

                },


            ]);
            setLoading(false)

        }

    }


    return (
        <>
            <div className="container">
                <div className="py-4"></div>
                <form onSubmit={handleSubmit(submitForm)} className='container'>
                    <div className="row">

                        <div className="col-lg-3 ">
                            <label className='pe-2'>From:</label>
                            <input
                                type="date"
                                {...register("fromDate", { required: "Please fill this field" })}
                                className='default-input py-2 rounded-1 fz-15px fw-normal' />
                            {errors?.fromDate && <ShowError text={errors.fromDate?.message} />}

                        </div>
                        <div className="col-lg-3 ">
                            <label className='pe-2'>To: </label>
                            <input type="date"
                                {...register("toDate", { required: "Please fill this field" })}

                                className='default-input  py-2 rounded-1 fz-15px fw-normal' />
                            {errors?.toDate && <ShowError text={errors.toDate?.message} />}
                        </div>
                        <div className="col-lg-2   mt-4">

                            <input type="submit" className='bgTomato text-white border-0 py-2 px-5 rounded-1 ' disabled={loading ? true : false} value={loading ? "Please Wait..." : "Submit"} />
                        </div>
                    </div>

                </form>
                <div className="py-4"></div>
                {chartData ?
                    <div className="w-100">
                        <BarChart
                            width={500}
                            height={300}
                            data={chartData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis domain={[0, 100]} tickFormatter={(value) => `${value}%`} /> {/* Displaying tick values as percentage */}
                            <YAxis />
                            <Tooltip formatter={(value) => `${value}%`} />
                            <Legend />
                            <Bar dataKey="Approved_Task" fill="#82ca9d" />
                            <Bar dataKey="Rejected_Task" fill="#f0999b" />
                            <Bar dataKey="Approve_Pending_Task" fill="#607d8b" />
                            <Bar dataKey="Incomplete_Task" fill="#f0c079" />

                        </BarChart>
                        <JsonViewer data={chartData} />
                    </div>
                    : "no user selected"}
            </div>
        </>
    )
}

export default BrandManagerPerformance
import React from 'react'
import { Modal } from 'react-bootstrap'
import "../../../css/modal.css"
function BootstrapModal({ isShowModal, setIsModal, title, body, fullscreen }) {
    return (
        <div>

            <Modal
                show={isShowModal}
                backdrop="static"
                fullscreen={fullscreen}
                size={"lg"}
                onHide={() => setIsModal(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                animation

            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" >
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body bsPrefix="px-4 pb-4">

                    {body}


                </Modal.Body>

            </Modal>
        </div>
    )
}

export default BootstrapModal
import React, { useEffect, useState } from "react";
import { BsFilterRight, BsPlusSquareDotted } from "react-icons/bs";
import { API_CONSTANTS, API_METHOD } from "../../../../api/apiConstants";
import { API_CALL } from "../../../../api/api_calls_services";
import moment from "moment/moment";
import LoaderWrapper from "../../../global-components/LoaderWrapper";
import { Accordion, Modal } from "react-bootstrap";
import JsonViewer from "../../../global-components/JsonViewer";
import { AiOutlineEye } from "react-icons/ai";
import { RiCloseFill } from "react-icons/ri";
import camStyles from "../../../../css/liveCam.module.css";

const imgStyle = {
  width: "100%",
  height: "350px",
  objectFit: "cover",
  borderRadius: "4px",
};
function InReviewTasks() {
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [taskFilter, setTaskFilter] = useState("DAILYTASK");
  const [inReviewTaskList, setInReviewTaskList] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    setInReviewTaskList(null);
    getAllInReviewTask();
  }, [taskFilter, date]);
  const getAllInReviewTask = async () => {
    const request = {
      apiUrl:
        API_CONSTANTS.BASE_URL +
        API_CONSTANTS.GET_PENDING_TRACK_TASKS +
        `/${date}` +
        `/${taskFilter}`,
      method: API_METHOD.GET,
    };
    let result = await API_CALL(request);
    if (result.statusCode === 200) {
      setInReviewTaskList(result.data);
    }
  };
  const onView = (data) => {
    setPreviewData(data);
    handleShow();
  };
  return (
    <div>
      <BsFilterRight />{" "}
      <span className=" font-os fw-500 fz-14px text-dark">
        Filter by date :{" "}
      </span>{" "}
      <input
        type="date"
        className="date"
        value={date}
        onChange={(e) => setDate(e.target.value)}
      />
      <span className="ms-2 font-os fw-500 fz-14px text-dark">Task type: </span>
      <select
        type="date"
        className="date pe-5"
        value={taskFilter}
        onChange={(e) => setTaskFilter(e.target.value)}
      >
        <option value={"DAILYTASK"}>Daily</option>
        <option value={"WEEKLYTASK"}>Weekly</option>
        <option value={"MONTHLYTASK"}>Monthly</option>
      </select>
      <div className="py-3"></div>
      {/* --------------- */}
      <LoaderWrapper
        noDataMsg={
          <h6 className="text-center text-muted">No task where are present</h6>
        }
        data={inReviewTaskList}
      >
        <div>
          {inReviewTaskList ? (
            <div className="sub-checklist">
              <table className="table-cust">
                <thead>
                  <tr>
                    <th className="w-50">CheckList</th>
                    <th>Expand</th>
                  </tr>
                </thead>
              </table>
              <Accordion defaultActiveKey={0} flush>
                {inReviewTaskList?.map((assignTaskDataItems, mainIndex) =>
                  assignTaskDataItems?.checkList?.length == 0 ? (
                    <h6 className="text-center text-muted py-4 fw-500">
                      No task present
                    </h6>
                  ) : (
                    assignTaskDataItems?.checkList?.map(
                      (checkListData, checkListIndex) => (
                        <Accordion.Item
                          eventKey={checkListIndex}
                          key={checkListData.id + mainIndex}
                          className="py-1"
                        >
                          <Accordion.Header>
                            <span className=" text-muted fz-14px">
                              {" "}
                              {checkListIndex + 1}.
                            </span>{" "}
                            <span className="text-dark mx-1 text-capitalize ">
                              {checkListData?.title}
                            </span>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="px-3">
                              <h6 className="text-muted">Sub checklist:</h6>
                              <table className="table-cust">
                                <thead>
                                  <tr>
                                    <th>SNo</th>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {checkListData?.subCheckList?.length ? (
                                    checkListData?.subCheckList?.map(
                                      (subCheckList, indx) => (
                                        <tr key={subCheckList?.sub_id}>
                                          <td>
                                            {(indx + 1)
                                              .toString()
                                              .padStart(2, "0")}
                                          </td>
                                          <td>{subCheckList.title}</td>
                                          <td>{subCheckList.description}</td>
                                          <td>
                                            {subCheckList?.imageLocation ||
                                            subCheckList?.videoLocation ? (
                                              <>
                                                <span className="text-success">
                                                  In Review
                                                </span>
                                              </>
                                            ) : (
                                              <span className="text-danger">
                                                Unfinished
                                              </span>
                                            )}
                                          </td>
                                          <td>
                                            {subCheckList?.s3Locations
                                              ?.length ||
                                            subCheckList?.videoLocation ? (
                                              <button
                                                className=" bg-success border-0 fz-14px  pb-1 text-white px-2 rounded-5"
                                                onClick={() =>
                                                  onView(subCheckList)
                                                }
                                              >
                                                <AiOutlineEye />
                                              </button>
                                            ) : (
                                              <button
                                                className=" bgTomato border-0 fz-14px pb-1  text-white px-2 rounded-pill"
                                                disabled
                                              >
                                                <RiCloseFill size={17} />
                                              </button>
                                            )}
                                          </td>
                                        </tr>
                                      )
                                    )
                                  ) : (
                                    <tr>
                                      <td colSpan={5} className="text-muted">
                                        No SubCheckList Available
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      )
                    )
                  )
                )}{" "}
              </Accordion>
            </div>
          ) : (
            ""
          )}
        </div>
      </LoaderWrapper>
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <JsonViewer data={previewData} />
          <Modal.Title>
            <>{previewData?.title} </>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <b>Description</b>: {previewData?.description}
          </p>
          <div className={`${camStyles.capturePrevHeight} `}>
            <Accordion defaultActiveKey={0} flush>
              {previewData?.s3Locations?.length ? (
                <>
                  {previewData?.s3Locations?.map(
                    (uploadedItems, index, array) =>
                      uploadedItems?.imageLocations ? (
                        <Accordion.Item
                          eventKey={index}
                          key={uploadedItems?.imageId + index}
                        >
                          <Accordion.Header>
                            Uploaded Preview {index + 1}
                          </Accordion.Header>
                          <Accordion.Body>
                            <a
                              href={uploadedItems?.imageLocations}
                              target="_blank"
                              className="pb-2 d-inline-block"
                            >
                              View full screen
                            </a>
                            <img
                              src={uploadedItems?.imageLocations}
                              style={imgStyle}
                              className=" d-block"
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      ) : (
                        <Accordion.Item
                          eventKey={index}
                          key={uploadedItems?.index + 1}
                        >
                          <Accordion.Header>
                            Uploaded Video Preview {index + 1}
                          </Accordion.Header>
                          <Accordion.Body>
                            <video
                              src={uploadedItems?.videoLocations}
                              controls
                              width="100%"
                              height="480px"
                            >
                              <source src="movie.mp4" type="video/mp4" />
                              <source src="movie.ogg" type="video/ogg" />
                            </video>
                          </Accordion.Body>
                        </Accordion.Item>
                      )
                  )}
                </>
              ) : (
                "No Uploads Found!"
              )}
            </Accordion>
          </div>
          {/* <div className="py-2"></div> */}
          {/* <p><b>Comments</b>:</p>
                    <textarea value={previewData?.comments} className=' form-control shadow-none' readOnly rows={4}></textarea> */}
        </Modal.Body>
      </Modal>
      <JsonViewer data={inReviewTaskList} />
    </div>
  );
}
export default InReviewTasks;

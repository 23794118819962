import { atom } from "recoil";

export const editBrandAtomFunc = atom({
  key: '224febb5-25ad-4887-9637-381aeb1e0f15', // unique ID (with respect to other atoms/selectors)
  default: {
    accordionActiveKey: "",
    accordionRefreshKey: "",
    refreshTabKey: "",
    tabActiveKey: "first",
    btnText:false,
    brandId:"",
    brandName:"",
  },
});

import React, { useCallback, useRef, useState } from 'react'
import { FaCamera } from 'react-icons/fa';
import { RiCameraSwitchFill } from 'react-icons/ri'
import { AiOutlineEye } from 'react-icons/ai'
import Webcam from 'react-webcam';
import { IoReloadOutline } from "react-icons/io5";
import { BsRecordCircle, BsStopCircle, BsCheck2 } from 'react-icons/bs'
import camStyles from "../../../../css/liveCam.module.css"
import { useRecoilState } from 'recoil';
import { liveImageCaptureAtom } from '../../../../recoil_states/AtomsStore';
import { useEffect } from 'react';
import { confirmSubmit } from '../../../global-components/utils/swalAlerts';
import { API_CONSTANTS, API_METHOD } from '../../../../api/apiConstants';
import { toast } from 'react-hot-toast';
import { API_CALL } from '../../../../api/api_calls_services';
import Swal from 'sweetalert2';
import { Accordion } from 'react-bootstrap';
function LiveVideoTask({ getAllAssignedTask, modalSubTaskData, setIsLiveVideo, setShowViewModal }) {

    const FACING_MODE_ENVIRONMENT = "environment";
    const FACING_MODE_USER = "user";

    const [comment, setComment] = useState(null)
    const [capturing, setCapturing] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [videoPreview, setVideoPreview] = useState(null);
    const [videoPreviewList, setVideoPreviewList] = useState([]);
    const [loading, setLoading] = useState(false)
    const [seconds, setSeconds] = useState(null);
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);
    const newChunksListRef = useRef([]);

    const videoConstraints = {
        width: 1280,
        height: 720,
        screenshotQuality: 1,
        facingMode: FACING_MODE_USER, //{ exact: "environment" } for rear camera
        aspectRatio: 0.6666666667,

    };

    const handleClick = useCallback(() => {
        setFacingMode(
            prevState =>
                prevState === FACING_MODE_USER
                    ? FACING_MODE_ENVIRONMENT
                    : FACING_MODE_USER
        );
    }, []);

    const handleDataAvailable = useCallback(
        ({ data }) => {
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
            }
        },
        [setRecordedChunks]
    );

    const handleStartCaptureClick = useCallback(() => {
        setRecordedChunks([])
        setVideoPreview(null)
        setCapturing(true);
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: "video/webm",
        });
        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );
        mediaRecorderRef.current.start();

    }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

    const handleStopCaptureClick = useCallback(() => {
        mediaRecorderRef.current.stop();
        setCapturing(false);

    }, [mediaRecorderRef, setCapturing]);
    const [recChunksList, setRecChunksList] = useState([])
    const handlePreview = () => {


        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, {
                type: "video/webm",
            });
            const url = URL.createObjectURL(blob);
            setVideoPreview(url)
            mediaRecorderRef.current.stop();
            setCapturing(false);

        }
    }

    useEffect(() => {
        if (recordedChunks.length) {
            handlePreview();
            //Calling after pressing stop video btn
        }
    }, [recordedChunks])

    const convertBlobToMultipart = () => {
        return new Promise((resolve, reject) => {
            const usrDetail = JSON.parse(localStorage.getItem("userDetail"));
            let requestOptions;
            let apiEndpoint;
            var myHeaders = new Headers();
            myHeaders.append("Authorization", 'Bearer ' + usrDetail?.token);
            var formdata = new FormData();
            formdata.append("comments", comment);
            formdata.append("checkList", modalSubTaskData?.checkListId);
            formdata.append("subCheckList", modalSubTaskData?.id);
            // Create an array to hold the files
            const files = [];

            // Iterate over blobs in newChunksListRef.current and create files
            newChunksListRef.current.forEach((blob, index) => {
                const file = new File([blob], `ATMS_${Date.now()}_${index}video.webm`, { type: "video/webm" });
                files.push(file);
            });

            // Iterate over the files array and append each file to formdata
            files.forEach((file, index) => {
                formdata.append("file", file);
            });

            // for (let pair of formdata.entries()) {
            //     console.log(pair[0], pair[1]);
            // }
            if (modalSubTaskData.rejectStatus) {
                // if update true
                apiEndpoint = `${API_CONSTANTS.BASE_URL}updateRejectsubListVideo`
                requestOptions = {
                    method: 'PUT',
                    body: formdata,
                    headers: myHeaders,
                };
            } else {
                apiEndpoint = `${API_CONSTANTS.BASE_URL}branchCheckListUpdateVideo`
                requestOptions = {
                    method: 'POST',
                    body: formdata,
                    headers: myHeaders,
                };
            }

            fetch(apiEndpoint, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.statusCode === 200) {
                        getAllAssignedTask();
                        toast.success("Uploaded")
                        setIsLiveVideo(false)
                        setShowViewModal(false)
                        setLoading(false);
                    } else if (result.statusCode > 200) {
                        setLoading(false);
                        Swal.fire({
                            icon: 'error',
                            title: result?.message,
                            text: result?.details,
                        })
                    }
                })
                .catch(error => console.log('error', error));

        });
    };

    const onUpload = async () => {
        const isClicked = await confirmSubmit({ title: "Are you sure to upload?", cancelText: "No", confirmText: "Yes" });
        if (isClicked.isConfirmed) {
            try {
                setLoading(true);
                const multipartFileData = await convertBlobToMultipart();

            } catch (error) {
                console.error("Error converting Blob to multipart form data:", error);
            }
        }
    }
    const handleConfirm = () => {
        setRecChunksList((prevChunksList) => prevChunksList.concat(recordedChunks));
        // console.log("recChunksList:", recChunksList);
        // console.log("recordedChunks:", recordedChunks);
        newChunksListRef.current = [...recChunksList, ...recordedChunks];
        // console.log("chukkkkkkkk!", newChunksListRef.current)
        setVideoPreviewList([...videoPreviewList, videoPreview])
        setCapturing(false);
        setVideoPreview(null)
    }
    return (
        <div>
            <div className="Container">

                <div className="mx-auto">
                    {videoPreview ? "" : <Webcam
                        width="100%"
                        height="480px"
                        audio={true}
                        mirrored={facingMode == FACING_MODE_ENVIRONMENT ? false : true}
                        ref={webcamRef}
                        videoConstraints={{
                            ...videoConstraints,
                            facingMode
                        }}
                    />}
                </div>
                {/* ------Video Preview----- */}
                {videoPreview ?
                    <video
                        src={videoPreview}
                        controls
                        width="100%"
                        height="480px">
                        <source src="movie.mp4" type="video/mp4" />
                        <source src="movie.ogg" type="video/ogg" />
                    </video> : ""}
                {/* ------Video Preview End----- */}



            </div>
            <div className='w-100 text-center'>
                {recordedChunks.length > 0 && videoPreview && (
                    <>
                        <button onClick={() => setVideoPreview(null)} className={`${camStyles.optionsContainer} ${camStyles.retake} `}><IoReloadOutline size={21} /></button>
                        <button className={`${camStyles.optionsContainer} ${camStyles.cam} me-2`} onClick={handleConfirm} title='Confirm'><BsCheck2 size={25} /> </button>
                    </>
                )}
                {capturing ? (

                    <button className={`${camStyles.optionsContainer} ${camStyles.cam} `} onClick={handleStopCaptureClick} title='Stop'><BsStopCircle color='#0d2839eb' size={20} /></button>

                ) : (
                    videoPreview ? "" : <button className={`${camStyles.optionsContainer} ${camStyles.cam} `} onClick={handleStartCaptureClick} title='Start'><BsRecordCircle color='red' size={20} /></button>
                )}

                {videoPreview ? "" : <button onClick={handleClick} className={`${camStyles.optionsContainer} ${camStyles.switch} mx-2`} title='Switch'><RiCameraSwitchFill size={22} /></button>}
            </div>

            {/* <textarea type="text" placeholder='Add comments' className='py-2 default-input w-100' cols={"10"} onChange={e => setComment(e.target.value)} /> */}

            {videoPreviewList.length ?
                <div className={`${camStyles.capturePrevHeight} `}>
                    <Accordion defaultActiveKey={0}>
                        {videoPreviewList.length ?
                            videoPreviewList.map((path, index, array) => (
                                <Accordion.Item eventKey={index} key={index + 1}>
                                    <Accordion.Header>Video Preview {index + 1} </Accordion.Header>
                                    <Accordion.Body>
                                        <video
                                            key={index + 1}
                                            src={path}
                                            controls
                                            width="100%"
                                            height="480px">
                                            <source src="movie.mp4" type="video/mp4" />
                                            <source src="movie.ogg" type="video/ogg" />
                                        </video>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))
                            : ""
                        }
                    </Accordion>
                </div>
                : ""}

            {/* ----------Upload/cancel btn----------- */}
            <div className='text-end'>
                {videoPreviewList.length ?
                    <button className={"border-0 bg-success fz-14px fw-500 text-white px-3 py-1 rounded-pill mb-2 mx-2"} onClick={onUpload} disabled={loading}
                    >{loading ?
                        <>
                            <div className="spinner-border  rounded-circle text-white spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div> Please wait...
                        </> : "Upload"}</button>
                    : ""}

                <button className={"border-0 bgTomato fz-14px fw-500 text-white mt-3 px-3 py-1 rounded-pill"} onClick={() => setIsLiveVideo(false)}> Cancel</button>


            </div>
        </div>

    )
}

export default LiveVideoTask

import React, { useLayoutEffect } from 'react'
import HeadingHeader from '../global-components/HeadingHeader';

function BranchManagerDashboard() {

  return (
    <div>
      <HeadingHeader title={"Branch Manager"} subTitle={"Dashboard"} />


    </div>
  )
}

export default BranchManagerDashboard;

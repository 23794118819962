import React, { useCallback, useRef, useState } from "react";
import Webcam from 'react-webcam';
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';
import JsonViewer from "../global-components/JsonViewer";
import { useEffect } from "react";
import { RiCameraSwitchFill } from "react-icons/ri";

function TestPage() {
    const [img, setImg] = useState(null);
    // const webcamRef = useRef(null);

    // const videoConstraints = {
    //     width: 420,
    //     height: 420,
    //     facingMode: "user",
    // };

    // const capture = useCallback(() => {
    //     const imageSrc = webcamRef.current.getScreenshot();
    //     setImg(imageSrc);
    // }, [webcamRef]);


    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [capturing, setCapturing] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [videoPreview, setVideoPreview] = useState(null);
    const [seconds, setSeconds] = useState(null);
    const FACING_MODE_ENVIRONMENT = "environment";
    const FACING_MODE_USER = "user";
    const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);
    useEffect(() => {
        if (seconds === 0) {
            setSeconds(null)
        }

        // exit early when we reach 0
        if (!seconds) return;

        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            setSeconds(seconds - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add seconds as a dependency to re-rerun the effect
        // when we update it
    }, [seconds]);
    const handleDataAvailable = useCallback(
        ({ data }) => {
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
            }
        },
        [setRecordedChunks]
    );

    const handleStartCaptureClick = useCallback(() => {
        setCapturing(true);
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: "video/webm",
        });
        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );
        mediaRecorderRef.current.start();
        setSeconds(30)
        // setTimeout(() => {
        //     handleStopCaptureClick()
        // }, 30000);
    }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

    const handleStopCaptureClick = useCallback(() => {
        mediaRecorderRef.current.stop();
        setSeconds(null)
        setCapturing(false);
    }, [mediaRecorderRef, setCapturing]);

    const handlePreview = useCallback(() => {
        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, {
                type: "video/webm",
            });

            const url = URL.createObjectURL(blob);
            setVideoPreview(url)
            // const a = document.createElement("a");
            // document.body.appendChild(a);
            // a.style = "display: none";
            // a.href = url;
            // a.download = "react-webcam-stream-capture.webm";
            // a.click();
            // window.URL.revokeObjectURL(url);
            // setRecordedChunks([]);
        }
    }, [recordedChunks]);

    const videoConstraints = {
        width: 420,
        height: 420,
        facingMode: facingMode,
    };
    console.log(recordedChunks)

    const handleClick = useCallback(() => {
        setFacingMode(
            prevState =>
                prevState === FACING_MODE_USER
                    ? FACING_MODE_ENVIRONMENT
                    : FACING_MODE_USER
        );
    }, []);
    const convertBlobToMultipart = (blob) => {
        return new Promise((resolve, reject) => {
            const file = new File([blob], "video.webm", { type: "video/webm" });

            const formData = new FormData();
            formData.append("file", file);

            resolve(formData);
        });
    };

    const upload = async () => {
        try {
            const multipartData = await convertBlobToMultipart(recordedChunks);
            for (let pair of multipartData.entries()) {
                console.log(pair[0], pair[1]);
            }
        } catch (error) {
            console.error("Error converting Blob to multipart form data:", error);
        }
    }
    return (
        <div className="Container">
            {/* <div className="Container">
                {img === null ? (
                    <>
                        <Webcam
                            audio={false}
                            mirrored={true}
                            height={400}
                            width={400}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                        />
                        <button onClick={capture}>Capture photo</button>
                    </>
                ) : (
                    <>
                        <img src={img} alt="screenshot" />
                        <button onClick={() => setImg(null)}>Retake</button>
                    </>
                )}
            </div> */}
            {/* {seconds} */}
            <Webcam
                height={400}
                width={400}
                audio={false}
                mirrored={facingMode == FACING_MODE_ENVIRONMENT ? false : true}
                ref={webcamRef}
                videoConstraints={videoConstraints}
            />
            {console.log(recordedChunks)}
            {facingMode == FACING_MODE_ENVIRONMENT ? "envi back" : "front"}
            <video src={videoPreview} controls width="320" height="240">
                <source src="movie.mp4" type="video/mp4" />
                <source src="movie.ogg" type="video/ogg" />
            </video>
            {capturing ? (
                <button onClick={handleStopCaptureClick}>Stop Record</button>
            ) : (
                <button onClick={handleStartCaptureClick}>Start Record</button>
            )}
            {recordedChunks.length > 0 && (
                <button onClick={handlePreview}>Preview</button>
            )}
            <button onClick={handleClick}  ><RiCameraSwitchFill size={22} /></button>
            <button onClick={upload}>upload</button>

        </div>
    )
}

export default TestPage
import React from "react";

function FooterComponents({ isOpen }) {
  return (
    <footer className={isOpen ? "footer1" : "footer"}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6">
            Copyright ©️ 2024{" "}
            <a href="https://ciarss.com" target="_blank">
              {" "}
              CIARSS.com
            </a>
            . All rights reserved
          </div>
          <div className="col-sm-6">
            <div className="text-sm-end d-none d-sm-block">Version 1.1.0</div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterComponents;


import React, { useState } from 'react';
import { Accordion, Col, Nav, Row, Tab } from 'react-bootstrap';
import { BiEditAlt } from "react-icons/bi";
import { useRecoilState, useRecoilValue } from 'recoil';
import { accordionDefaultActiveKeyRecoilState } from '../../../recoil_states/taskEditAtoms';
import HeadingHeader from '../../global-components/HeadingHeader';
import AssignTask from './action_forms/assign-task';
import CheckListForm from './action_forms/checklist-form';
import SubTaskListForm from './action_forms/subTaskListForm';
import AssignTaskTable from './action_forms/table_views_for_action_menu/AssignTaskTable';
import CheckListTable from './action_forms/table_views_for_action_menu/checklist-table';
import SubTaskListTable from './action_forms/table_views_for_action_menu/SubTaskListTable';
// import {BiEditAlt} from "react-icons/bi"

function BranchAdminCreateTask() {
  const [checklistTab, setChecklistTab] = useState("Checklist");
  const accKey = useRecoilValue(accordionDefaultActiveKeyRecoilState)
  function currentTabClick(clicktab) {
    setChecklistTab(clicktab);
  }

  return (
    <>
      <HeadingHeader title={"Create CheckList"} subTitle={"Create Task"} />

      <div className='py-4  px-lg-4 container all-brd-0'>

        <Accordion key={accKey?.triggerReRender} defaultActiveKey={accKey?.accordionActiveKey}>
          <Accordion.Item eventKey="0" className="create-task-menu">
            <Tab.Container id="left-tabs-example" defaultActiveKey={accKey?.tabActiveKey}>
              <Accordion.Header><BiEditAlt className='me-1' />Action Menu</Accordion.Header>
              <Row>
                <Col sm={12}>
                  <Nav variant="pills" >
                    <Nav.Item>
                      <Nav.Link eventKey="Checklist" onClick={(e) => { currentTabClick("Checklist") }}> Checklist</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="subChecklist" onClick={(e) => { currentTabClick("subChecklist") }}>SubChecklist</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Assign" onClick={(e) => { currentTabClick("Assign") }}>Assign</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
              </Row>
              <Accordion.Body>
                {checklistTab == "Checklist" ? <CheckListForm /> : null}
                {checklistTab == "subChecklist" ? <SubTaskListForm /> : null}
                {checklistTab == "Assign" ? <AssignTask /> : null}


              </Accordion.Body>
            </Tab.Container>
          </Accordion.Item>

        </Accordion>

        <div className='py-4'></div>
        {checklistTab == "Checklist" ? <CheckListTable /> : null}
        {checklistTab == "subChecklist" ? <SubTaskListTable /> : null}
        {checklistTab == "Assign" ? <AssignTaskTable /> : null}


      </div>

    </>
  )
}

export default BranchAdminCreateTask
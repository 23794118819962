import React from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import ApprovedTasks from './approvedTask/ApprovedTasks';
import RejectedTasks from './rejectedTask/RejectedTasks';
import InReviewTasks from './InreviewTask/InReviewTasks';

function TrackStatus() {
    return (
        <div className='container-fluid' id="branch_task" >
            <div className="py-3"></div>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first" mountOnEnter unmountOnExit>
                <Row >
                    <Col sm={12}>
                        <Nav variant="pills" className=" justify-content-center ">
                            <Nav.Item>
                                <Nav.Link eventKey="first">Task Status</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">Approved Task</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="third">Rejected Task</Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item>
                                <Nav.Link eventKey="four">MONTHLY</Nav.Link>
                            </Nav.Item> */}
                        </Nav>
                    </Col>
                    <div className="py-3"></div>
                    <Col sm={12}>
                        <Tab.Content>

                            <Tab.Pane eventKey="first">
                                <InReviewTasks />
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <ApprovedTasks />

                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <RejectedTasks />

                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>


        </div>
    )
}

export default TrackStatus
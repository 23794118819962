export const reSelectStyle = {
    control: (base, state) => ({
        ...base,
        '&:hover': {
            borderColor: state.isFocused ? "" : ""
        },
        borderColor: state.isFocused ? "#373d47" : "#808994",
        // This line disable the blue border
        boxShadow: state.isFocused ? "none" : "",

    })
}
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-hot-toast'
import { AiOutlineEye } from 'react-icons/ai'
import { FaRegTrashAlt } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'
import { usePagination, useTable } from 'react-table'
import { useRecoilState } from 'recoil'
import { API_CONSTANTS, API_METHOD } from '../../../../../api/apiConstants'
import { API_CALL } from '../../../../../api/api_calls_services'
import { trackGetAllCheckListStateChange } from '../../../../../recoil_states/getchecklist'
import { accordionDefaultActiveKeyRecoilState, checkListDataRecoilState } from '../../../../../recoil_states/taskEditAtoms'
import { confirmDeleteAlert } from '../../../../global-components/utils/swalAlerts'


function CheckListTable() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [checkListRowData, setViewCheckListRowData] = useState("");
    const [checkSubListRowData, setSubListRowData] = useState([]);
    const [allCheckList, setAllCheckList] = useState([]);
    const [checkListDataAtom, setCheckListDataAtom] = useRecoilState(checkListDataRecoilState)//get clicked checklist data
    const [openActionMenuAccordion, setOpenActionMenuAccordion] = useRecoilState(accordionDefaultActiveKeyRecoilState) // open accordion state
    const [checkListState, setCheckListState] = useRecoilState(trackGetAllCheckListStateChange)


    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getAllCheckList();
    }, [checkListState])
    const onDeleteCheckList = async (dataId) => {
        const isClicked = await confirmDeleteAlert({ title: "Are you sure want to delete this row?", cancelText: "No", confirmText: "Yes" });
        if (isClicked.isConfirmed) {
            setLoading(true);
            const requestBodyParam = {
                method: 'DELETE',
                redirect: 'follow'
            };
            const request = {
                apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.DELETE_CHECK_LIST + `/${dataId}`,
                method: API_METHOD.DELETE,
                requestBodyParams: requestBodyParam,
            }
            let result = await API_CALL(request)
            if (result.statusCode === 200) {
                setCheckListState(result.message);
                setLoading(false);
                toast.success("Checklist deleted successfully")

            }
        }
    }
    const onEditCheckList = (gettingSelectedCheckListData) => {
        setOpenActionMenuAccordion({ accordionActiveKey: "0", tabActiveKey: "Checklist", triggerReRender: Math.random() })//the defaultevent key for accordion was "0" so using "0"
        setCheckListDataAtom(gettingSelectedCheckListData)//set clicked checklist data
    }
    const getAllCheckList = async () => {
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_ALL_CHECK_LIST,
            method: API_METHOD.GET,
        }
        let result = await API_CALL(request)
        // alert(JSON.stringify(result[0].title));


        if (result.statusCode === 200) {
            // alert(JSON.stringify(result.data[0].courseOverview));
            // reset();
            setAllCheckList(result.message);
            // console.log(JSON.stringify(result), "=================");


        } else if (result.statusCode > 200) {
            console.log('errror', result)
        }
    }
    const data = React.useMemo(
        () => {

            const rowData = allCheckList.map((datas, i) => ({
                ...datas, SNo: i + 1
            }
            ));
            return rowData
        }, [allCheckList]
    )
    const handleViewChecklist = (clickedRowData) => {
        setViewCheckListRowData(clickedRowData);
        setSubListRowData([clickedRowData])
        handleShow();
    }

    const columns = React.useMemo(
        () => [
            {
                Header: '#',
                accessor: 'SNo', // accessor is the "key" in the data
            },
            // {
            //     Header: 'ID',
            //     accessor: 'col1', // accessor is the "key" in the data
            // },
            {
                Header: "Checklist",
                accessor: 'title',
            },
            // { 
            //     Header: "Description",
            //     accessor: "description",
            // },
            // {
            //     Header: "TASK TYPE",
            //     accessor: "taskType",
            // },
            {
                Header: "CREATED DATE",
                accessor: "createDate",
            },
            // {
            //     Header: "CREATED TIME",
            //     accessor: "createTime",
            // },
            // {
            //     Header: 'STATUS',
            //     accessor: 'status',
            //     Cell: row => (<div>
            //         {row.row.original.taskState === "INACTIVE" ? <button className="task-active-btn bg-danger" onClick={() => alert(row.row.original)}>{row.row.original.taskState}</button> : ""}
            //         {row.row.original.taskState === "ACTIVE" ? <button className="task-active-btn " onClick={() => alert(row.row.original)}>{row.row.original.taskState}</button> : ""}

            //     </div>),
            // },
            // {
            //     Header: 'VIEW',
            //     accessor: 'view',
            //     Cell: row => (<div>
            //         <button className="border-0 bg-transparent  text-dark" onClick={() => handleViewChecklist(row.row.original)}><AiOutlineEye /></button>

            //     </div>),
            // },
            {
                Header: 'ACTION',
                accessor: 'delete',
                Cell: row => (<div>
                    {/* <button className="border-0 bg-transparent  " onClick={() => handleViewChecklist(row.row.original)}><FiUser /></button> */}
                    <button className="border-0 bg-transparent  text-primary" onClick={() => handleViewChecklist(row.row.original)}><AiOutlineEye /></button>
                    <button className="border-0 bg-transparent text-success me-2" onClick={() => onEditCheckList(row.row.original)}><FiEdit size={17} /></button>
                    <button className="border-0 bg-transparent  text-danger" onClick={() => onDeleteCheckList(row.row.original.id)}><FaRegTrashAlt /></button>

                </div>),
            }
        ],
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        usePagination
    )
    return (
        <>
            <h6>CheckListTable Data</h6>
            {/* {
                allCheckList ? allCheckList.map((data, i) => (
                    <div key={i}>{data.title}{i + "fdfdsjh"}</div>
                )) : "Loading"
            } */}
            {allCheckList ?
                <table {...getTableProps()} className="table-cust">
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}

                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table> : <div className="text-center">
                    <div className="spinner-border rounded-circle text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div></div>}


            {/* <div className="pagination py-2">
                <button className='border-0 p-2 ' type='button' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    <MdOutlineSkipPrevious />
                </button>
                <button className='border-0 p-2 ' type='button' onClick={() => previousPage()} disabled={!canPreviousPage}>
                    <IoIosArrowBack size={14} />
                </button>
                <button className='border-0 p-2' type='button' onClick={() => nextPage()} disabled={!canNextPage}>
                    <IoIosArrowForward size={14} opacity={6} />
                </button>
                <button className='border-0 p-2' type='button' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    <MdOutlineSkipNext />
                </button>
                <span className='d-flex  align-self-center m-2 fz-15px '>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>

                <select
                    className='fz-15px'
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select> */}
            {/* =======================Modal Popup to show data ============= */}
            <Modal
                show={show}
                size="lg"
                onHide={handleClose}
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title><>CheckList Title: {checkListRowData.title}</></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>

                        <div className="table-responsive">
                            <table className='table-cust'>

                                <tbody>
                                    <tr>
                                        <th>S.no</th>
                                        <th>CheckList</th>
                                        <th>Description</th>
                                        <th>Task State</th>
                                        <th> Task Type</th>
                                        <th>Created Date</th>
                                        <th>Created Time</th>
                                    </tr>
                                    <tr>

                                        <td>{checkListRowData.SNo}</td>
                                        <td>{checkListRowData.title}</td>
                                        <td>{checkListRowData.description}</td>
                                        <td>{checkListRowData.taskState}</td>
                                        <td>{checkListRowData.taskType}</td>
                                        <td>{checkListRowData.createDate}</td>
                                        <td>{checkListRowData.createTime}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        {checkSubListRowData ?
                            <div className="table-responsive">
                                <h5 className='py-3'>Sub CheckList</h5>
                                {checkSubListRowData.map((item, index) => {
                                    return (
                                        <div key={index}>


                                            <table className="table-cust" >

                                                <tbody>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>SubChecklist</th>
                                                        <th>Description</th>
                                                        <th>TaskState</th>
                                                        <th>CreateDate</th>
                                                    </tr>
                                                    {
                                                        item.subCheckList.map((subitem, i) => {
                                                            return (

                                                                <tr key={i}>
                                                                    <td>{i + 1}</td>
                                                                    <td>{subitem.title}</td>

                                                                    <td>{subitem.description}</td>

                                                                    <td>{subitem.taskState}</td>

                                                                    <td>{subitem.createDate}</td>
                                                                </tr>

                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            {item.subCheckList.length ? "" : <h6 className='text-center  text-muted py-3 card'> No SubCheckList Availabel </h6>}
                                        </div>

                                    )
                                })}
                            </div>
                            : ""}
                    </>

                </Modal.Body>

            </Modal>

        </>
    )
}

export default CheckListTable
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes/privateRoutes';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Components/home';
import LayoutWrapper from './Components/global-components/layoutWrapper';
import SuperAdminDashboard from './Components/superadmin/dashboard';
import RegisterBrand from './Components/superadmin/register';
import BrandAdminDashboard from './Components/branch_admin/dashboard';
import CreateBranchManagerUser from './Components/branch_admin/create_user/createUser';
import EmailAlertSending from './Components/branch_admin/email/emailAlert';
import BranchAdminCreateTask from './Components/branch_admin/branch_admin_create_task/createTask';
import VerifyTask from './Components/branch_admin/task_verification/verify-task';
import BranchManagerDashboard from './Components/branch_manager/dashboard';
import BranchManagerTask from './Components/branch_manager/task';
import { useEffect, useState } from 'react';
import NotFound404 from './Components/404NotFound';
import CheckRole from './PrivateRoutes/checkRole';
import BrandAdminAnalytics from './Components/branch_admin/ba-analytics/analytics';
import TestPage from './Components/test/testPage';
import { Toaster } from 'react-hot-toast';
import "./css/colors.css"
import TrackStatus from './Components/branch_manager/track-status/TrackStatus';
import BrandManagerPerformance from './Components/branch_manager/performance/BrandManagerPerformance';
function App() {
  useEffect(() => {
    const checkInternetStatus = () => {
      if (!navigator.onLine) {
        alert('Please Turn On Your Internet Connection');
      }
    };

    // Check the internet status on component mount
    checkInternetStatus();
  }, []);


  return (
    <>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          duration: 4000,
        }}
      />
      <BrowserRouter>
        <Routes>
          <Route path="/test" element={<TestPage />} />
          <Route path="/login" element={<PrivateRoutes login={true} />} />
          <Route path="/" element={<PrivateRoutes />}>
            {/* PRODUCT MANAGER Routes-----*/}
            <Route path="product-manager" element={
              <LayoutWrapper componentIdentifier="PRODUCT_MANAGER">
                <CheckRole>
                  <SuperAdminDashboard />
                </CheckRole>
              </LayoutWrapper>
            } />
            <Route path="" element={
              <LayoutWrapper componentIdentifier="PRODUCT_MANAGER">
                <SuperAdminDashboard />
              </LayoutWrapper>
            } />
            <Route path="/product-manager/register" element={
              <LayoutWrapper componentIdentifier="PRODUCT_MANAGER">
                <RegisterBrand />
              </LayoutWrapper>
            } />
            <Route path="*" element={
              <LayoutWrapper>
                <NotFound404 />
              </LayoutWrapper>
            } />
            {/* PRODUCT MANAGER ROUTES END-----*/}

            {/* BRAND ADMIN ROUTES -----*/}

            <Route path="/brand-admin/analytics" element={
              <LayoutWrapper componentIdentifier="BRAND_ADMIN">
                <BrandAdminAnalytics />
              </LayoutWrapper>
            } />
            <Route path="/brand-admin" element={
              <LayoutWrapper componentIdentifier="BRAND_ADMIN">
                <BrandAdminDashboard />
              </LayoutWrapper>
            } />
            <Route path="/brand-admin/create_user" element={
              <LayoutWrapper componentIdentifier="BRAND_ADMIN">
                <CreateBranchManagerUser />
              </LayoutWrapper>
            } />
            <Route path="/brand-admin/email" element={
              <LayoutWrapper componentIdentifier="BRAND_ADMIN">
                <EmailAlertSending />
              </LayoutWrapper>
            } />
            <Route path="/brand-admin/create-task" element={
              <LayoutWrapper componentIdentifier="BRAND_ADMIN">
                <BranchAdminCreateTask />
              </LayoutWrapper>
            } />
            <Route path="brand-admin/verify-task" element={
              <LayoutWrapper componentIdentifier="BRAND_ADMIN">
                <VerifyTask />
              </LayoutWrapper>
            } />

            {/* BRAND ADMIN ROUTES END -----*/}
            {/* BRANCH MANAGER ROUTES -----*/}

            <Route path="/performance" element={
              <LayoutWrapper componentIdentifier="BRAND_ADMIN">
                <BrandManagerPerformance />
              </LayoutWrapper>
            } />
            <Route path="/branch-manager" element={
              <LayoutWrapper componentIdentifier="BRAND_ADMIN">
                <BranchManagerDashboard />
              </LayoutWrapper>
            } />
            <Route path="/branch-manager/task" element={
              <LayoutWrapper componentIdentifier="BRAND_ADMIN">
                <BranchManagerTask />
              </LayoutWrapper>
            } />
            <Route path="/branch-manager/track-status" element={
              <LayoutWrapper componentIdentifier="BRAND_ADMIN">
                <TrackStatus />
              </LayoutWrapper>
            } />

            {/* BRANCH MANAGER ROUTES END-----*/}
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

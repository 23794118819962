import React from 'react';
import { toast } from 'react-hot-toast';
import { useRecoilState, useResetRecoilState } from 'recoil';
import Swal from 'sweetalert2';
import { API_CONSTANTS, API_METHOD } from '../../api/apiConstants';
import { API_CALL } from '../../api/api_calls_services';
import { editBrandAtomFunc } from '../../recoil_states/editBrandAtom';
import { refetchBrandAtomState } from '../../recoil_states/refetchBrandAtom';
function CreateBrandForm() {
    const [getBrandTrigger, setGetBrandTrigger] = useRecoilState(refetchBrandAtomState)
    const [brandEditAtom, setBrandEditAtom] = useRecoilState(editBrandAtomFunc)
    const resetBrandEditAtom = useResetRecoilState(editBrandAtomFunc);


    const handleSubmitFromCreateBrand = (e) => {
        e.preventDefault()
        if (brandEditAtom.btnText == "Save") {
            updateBrandApi(e)
        } else {
            createBrandApi(e)
        }
    }
    const createBrandApi = async (formElements) => {
        const { brandName } = formElements.target.elements
        let request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.CREATE_BRAND_END_POINT,
            method: API_METHOD.POST,
            requestBodyParams: JSON.stringify({
                brandName: brandName.value,
            })
        }
        let result = await API_CALL(request)
        if (result.statusCode === 200) {
            document.forms[0].reset();
            toast.success("Brand created successfully!")
            setGetBrandTrigger({ "brandRefetch": Math.random() })

        } else if (result.status > 200) {
            console.log(result);
        }
    }
    const updateBrandApi = async (formElements) => {
        const { brandName } = formElements.target.elements
        let request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.UPDATE_BRAND + `${brandEditAtom?.brandId}`,
            method: API_METHOD.PUT,
            requestBodyParams: JSON.stringify({
                brandName: brandName.value,
            })
        }
        let result = await API_CALL(request)
        if (result.statusCode === 200) {
            resetBrandEditAtom();
            toast.success("Brand updated successfully!")
            setGetBrandTrigger({ "brandRefetch": Math.random() })

        } else if (result.status > 200) {
            Swal.fire("Error", result.message);
        }
    }
    return (
        <div>

            <form onSubmit={handleSubmitFromCreateBrand} className="register-brand-style">
                <div className="container py-3">
                    <input type="text" id='brandName' defaultValue={brandEditAtom?.brandName} className='rounded-0 border-1 py-1 w-100' placeholder='Create brand name' required autoFocus />
                </div>
                <footer>
                    <div className='container'>
                        <div className='d-flex justify-content-end'>
                            <input type="submit" className='listsubmit-btn' value={brandEditAtom.btnText ? brandEditAtom.btnText : "Submit"} />
                            {brandEditAtom.btnText ? <button type='button' className='reset-btn' role="button" onClick={resetBrandEditAtom}>Cancel</button> : <input type="reset" value="Reset" className='reset-btn' />}
                        </div>
                    </div>
                </footer>
            </form>

        </div>
    )
}
export default CreateBrandForm


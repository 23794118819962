import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useRecoilState } from 'recoil';
import { API_CONSTANTS, API_METHOD } from '../../../../api/apiConstants';
import { API_CALL } from '../../../../api/api_calls_services';
import { trackGetAllCheckListStateChange } from '../../../../recoil_states/getchecklist';
import { subCheckListDataRecoilState } from '../../../../recoil_states/taskEditAtoms';
import JsonViewer from '../../../global-components/JsonViewer';

function SubTaskListForm() {
    const [formValues, setFormValues] = useState([{}])
    const [allCheckList, setAllCheckList] = useState([])
    // const [mainTitle, setMainTitle] = useState("")
    const [loading, setLoading] = useState(false);
    const [checkListState, setCheckListState] = useRecoilState(trackGetAllCheckListStateChange)
    const [subCheckListDataAtom, setSubCheckListDataAtom] = useRecoilState(subCheckListDataRecoilState)
    const { register, formState: { errors }, handleSubmit, reset } = useForm();
    useEffect(() => {
        (async () => {
            const getChkList = await getAllCheckList();
            let defaultValues = {};
            if (subCheckListDataAtom?.checkList_id) {
                defaultValues.checklistMainTitle = subCheckListDataAtom?.checkList_id;
                defaultValues.subCheckListTitle = subCheckListDataAtom?.title;
                defaultValues.subCheckListDescription = subCheckListDataAtom?.description;
                reset({ ...defaultValues });
            }
        })();
    }, []);

    const cancelEdit = () => {
        setSubCheckListDataAtom("");
        reset(
            {
                checklistMainTitle: "",
                subCheckListTitle: "",
                subCheckListDescription: "",

            }
        )
    }
    const getAllCheckList = async () => {
        const request = {
            apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_ALL_CHECK_LIST,
            method: API_METHOD.GET,
        }
        let result = await API_CALL(request)

        if (result.statusCode === 200) {

            setAllCheckList(result.message);

        } else if (result.statusCode > 200) {
            console.log('errror', result)
        }
    }



    let onSubmit = async (data) => {
        if (subCheckListDataAtom?.checkList_id) {
            setLoading(true);
            const requestBodyParam = JSON.stringify({
                title: data.subCheckListTitle,
                description: data.subCheckListDescription,
                taskState: subCheckListDataAtom?.taskState,
                checkListId: data.checklistMainTitle // checklist id
            });
            const request = {
                apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.UPDATE_SUB_CHECKLIST + `/${subCheckListDataAtom?.id}`,//passing checklist id
                method: API_METHOD.PUT,
                requestBodyParams: requestBodyParam,
            }
            let result = await API_CALL(request)

            if (result.statusCode === 200) {
                setLoading(false);
                setCheckListState(Math.random())// to trriger change
                cancelEdit();
                toast.success('SubChecklist was updated successfully!')
            }
        } else {
            setLoading(true);
            const requestBodyParam = JSON.stringify({
                title: data.subCheckListTitle,
                description: data.subCheckListDescription,
                checkListId: data.checklistMainTitle
            });
            const request = {
                apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.CREATE_SUB_CHECK_LIST,
                method: API_METHOD.POST,
                requestBodyParams: requestBodyParam,
            }
            let result = await API_CALL(request)
            if (result.statusCode === 200) {
                setLoading(false);
                setCheckListState(Math.random())// to trriger change
                reset()
                toast.success('SubChecklist was created successfully!')

            }
        }
    }

    return (
        <>


            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='container py-3 checklist-form'>
                    <div className="row">
                        <div className="col">
                            <select type="text" className='me-3' placeholder='Task Title...' name="selectChecklistTitle"{...register("checklistMainTitle", { required: true })}>
                                <option value="" style={{ color: "grey" }}>Select Checklist Title</option>
                                {
                                    allCheckList ? allCheckList.map((datas, idx) => (
                                        <option value={datas.id} key={idx}>{datas.title}</option>
                                    )) : <option value="">No Data Found!</option>
                                }
                            </select>
                            <div className='warn'>{errors.checklistMainTitle?.type === 'required' && "Please Fill the field "}</div>

                        </div>
                        <div className="col">
                            <input type="text" className='me-3' placeholder='Sub Task Title...' name={`taskTitle`} {...register("subCheckListTitle", { required: true })} />
                            <div className='warn'>{errors.subCheckListTitle?.type === 'required' && "Please Fill the field "}</div>

                        </div>
                        <div className="col">
                            <input type="text" className='me-3' placeholder='Description...' name={`taskDesc`} {...register("subCheckListDescription", { required: true })} />
                            <div className='warn'>{errors.subCheckListDescription?.type === 'required' && "Please Fill the field "}</div>

                        </div>
                    </div>

                    <div className="py-2"></div>
                </div>
                <footer className='bg-prime py-2'>
                    <div className='container'>
                        <div className='d-flex justify-content-end'>

                            {subCheckListDataAtom?.checkList_id ? <input type="button" onClick={cancelEdit} value="Cancel" className='reset-btn' /> : <input type="button" onClick={() => { reset() }} value="Reset" className='reset-btn' />}
                            {subCheckListDataAtom?.checkList_id ? <input type="submit" className='listsubmit-btn' disabled={loading ? true : false} value={loading ? "Please Wait..." : "Save"} /> : <input type="submit" className='listsubmit-btn' disabled={loading ? true : false} value={loading ? "Please Wait..." : "submit"} />}

                        </div>
                    </div>

                </footer>
            </form>
            <JsonViewer data={subCheckListDataAtom} />
        </>
    )
}

export default SubTaskListForm


import moment from 'moment/moment';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { AiFillCaretUp } from 'react-icons/ai';
import { FiLogOut } from 'react-icons/fi';
import { GiHamburgerMenu } from 'react-icons/gi';
import { GoGear } from 'react-icons/go';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { getLoggedInUserDetail } from '../../api/loggedInUserDetail';


const dropDownStyle = { "zIndex": "99", "position": "absolute", "width": "250px", "right": "0", "background": "#f2f3f4", "padding": "19px 20px", "borderRadius": "10px", "marginTop": "16px" }
const dropDownCard = { "listStyleType": "none" }
const dropDownLi = { "borderBottom": "1px solid #ebebeb", "padding": "10px 0px 10px 0px" }

function MenBarComponent({ toggle, isOpen }) {
    const [userData, setUserData] = useState("")
    const [isShowSettings, setIsShowSetting] = useState(false);
    const navigate = useNavigate();
    const logout = (e) => {
        localStorage.removeItem('userDetail');
        navigate("/login")
    }

    const onSettings = () => {
        setUserData(JSON.parse(localStorage.getItem("userDetail")));
        setIsShowSetting((prev) => !prev)
    }
    return (
        <div>
            <div className='desktop-view'>
                <Navbar className="bg-nav test " expand="lg">
                    <Container className="p-0 mx-2">
                        <Navbar.Brand onClick={toggle} role={"button"}><div className=' position-relative' >{isOpen ? <GiHamburgerMenu color="grey" size={22} /> : (<GiHamburgerMenu color="grey" size={22} />)}<div className='vr-line'></div></div>  </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ms-auto">
                                <div className="position-relative">
                                    <div className='vr-line2'></div>
                                    <Nav.Link to="#" onClick={(e) => e.preventDefault()} role={"cell"}><span className='text-light-black fz-14px fw-600'> {moment().format('hh:mm')}</span> <span className='fz-13px fw-600 opacity-75'>{moment().format('A')}</span></Nav.Link>
                                </div>
                                <div className="position-relative">
                                    <div className='vr-line2'></div>
                                    <Nav.Link to="#" onClick={(e) => e.preventDefault()} role={"cell"}> <span className='text-light-black fz-14px fw-600'> {moment().format('D')}</span> <span style={{ fontSize: "13.9px" }} className='fw-600 opacity-75'>{moment().format('MMMM  YYYY')}</span></Nav.Link>

                                </div>
                                <div className="position-relative">
                                    <div className='vr-line2'></div>
                                    <Nav.Link to="#home" onClick={onSettings}>  <GoGear color="grey" size={20} /> </Nav.Link>
                                    {isShowSettings ?
                                        <div style={dropDownStyle}>
                                            <ul style={dropDownCard} className="m-0 p-0">
                                                <AiFillCaretUp style={{ "position": "absolute", "right": "-2px", "color": "#f2f3f4", "top": "-17px", "fontSize": "36px", "zIndex": "0" }} />
                                                <li style={dropDownLi} className="d-block" title="User Name">
                                                    {userData?.displayName}    (id-{userData?.userId})
                                                </li>
                                                <li style={dropDownLi} className="d-block" title="Email">
                                                    {userData?.mail}
                                                </li>

                                            </ul>
                                        </div>
                                        : ""
                                    }

                                </div>
                                <div className="position-relative">
                                    <div className='vr-line2'></div>
                                    <Nav.Link to="/login"><FiLogOut color="grey" size={20} onClick={logout} /></Nav.Link>
                                </div>
                                {/* <Nav.Link to="#link"><IoMdNotifications color="grey" size={20} /></Nav.Link> */}


                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
            <div className='mobile-view'>
                <div className="d-flex justify-content-end py-3 px-3 bg-nav position-relative">

                    <div className="position-relative">
                        <div className='vr-line2'></div>
                        <Nav.Link to="#" onClick={(e) => e.preventDefault()} role={"cell"}><span className='text-light-black fz-14px fw-600'> {moment().format('hh:mm')}</span> <span className='fz-13px fw-600 opacity-75'>{moment().format('A')}</span></Nav.Link>
                    </div>
                    <div className="position-relative ps-3">
                        <div className='vr-line2'></div>
                        <Nav.Link to="#" onClick={(e) => e.preventDefault()} role={"cell"}> <span className='text-light-black fz-14px fw-600'> {moment().format('D')}</span> <span style={{ fontSize: "13.9px" }} className='fw-600 opacity-75'>{moment().format('MMMM  YYYY')}</span></Nav.Link>

                    </div>
                    <div className="position-relative px-3">
                        <div className='vr-line2'></div>
                        <Nav.Link to="#home" onClick={onSettings}>  <GoGear color="grey" size={20} /> </Nav.Link>
                        {isShowSettings ?
                            <div style={dropDownStyle}>
                                <ul style={dropDownCard} className="m-0 p-0">
                                    <AiFillCaretUp style={{ "position": "absolute", "right": "-2px", "color": "#f2f3f4", "top": "-17px", "fontSize": "36px", "zIndex": "0" }} />
                                    <li style={dropDownLi} className="d-block" title="User Name">
                                        {userData?.displayName}    (id-{userData?.userId})
                                    </li>
                                    <li style={dropDownLi} className="d-block" title="Email">
                                        {userData?.mail}
                                    </li>

                                </ul>
                            </div>
                            : ""
                        }
                    </div>
                    <div className="position-relative">
                        <div className='vr-line2'></div>
                        <Nav.Link to="/login"><FiLogOut color="grey" size={20} onClick={logout} /></Nav.Link>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default MenBarComponent;

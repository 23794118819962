import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { BsEye, BsFilterRight, BsPlusSquareDotted } from "react-icons/bs";
import { API_CONSTANTS, API_METHOD } from '../../../../api/apiConstants';
import { API_CALL } from '../../../../api/api_calls_services';
import JsonViewer from '../../../global-components/JsonViewer';
import LoaderWrapper from '../../../global-components/LoaderWrapper';
import moment from 'moment';

function ApprovedTasksList() {
  const [allTaskData, setAllTaskData] = useState(null);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [taskFilter, setTaskFilter] = useState("DAILYTASK");

  useEffect(() => {
    setAllTaskData(null)
    getAllAssignedApprovePendingTask();
  }, [date, taskFilter])

  const getAllAssignedApprovePendingTask = async () => {

    const request = {
      apiUrl: API_CONSTANTS.BASE_URL + API_CONSTANTS.GET_APPROVED_TASK + `/${date}` + `/${taskFilter}`,
      method: API_METHOD.GET,
    }
    let result = await API_CALL(request)
    if (result.statusCode === 200) {
      setAllTaskData(result.data)

    }
  }
  const onAction = () => {

  }
  return (
    <>
      <div>
        <div className="pt-2 pb-4">
          <BsFilterRight /> <span className=' font-os fw-500 fz-14px text-dark'>Filter by date : </span> <input type='date' className='date' value={date} onChange={(e) => setDate(e.target.value)} />
          <span className='ms-2 font-os fw-500 fz-14px text-dark'>Task type: </span>
          <select type='date' className='date pe-5' value={taskFilter} onChange={(e) => setTaskFilter(e.target.value)}  >
            <option value={"DAILYTASK"}>Daily</option>
            <option value={"WEEKLYTASK"}>Weekly</option>
            <option value={"MONTHLYTASK"}>Monthly</option>
          </select>
        </div>
        <LoaderWrapper noDataMsg={<h6 className='text-center text-muted'>No task present</h6>} data={allTaskData}>

          <div className='sub-checklist'>

            <table className="table-cust" >
              <thead>
                <tr>
                  <th className='w-50'>
                    CheckList
                  </th>
                  <th>
                    Expand
                  </th>
                </tr>

              </thead>
            </table >
            <Accordion defaultActiveKey={0} flush>{
              allTaskData?.map((assignTaskDataItems, mainIndex) => (
                assignTaskDataItems?.checkList?.map((checkListData, checkListIndex) => (
                  <Accordion.Item eventKey={mainIndex} key={checkListData.id + mainIndex} className="py-1">
                    <Accordion.Header> <span className=' text-muted fz-14px'>  {mainIndex + 1}.</span> <span className='text-dark mx-1 text-capitalize '> User name- <b>{assignTaskDataItems?.userDetail?.userName}</b> ({checkListData?.title})</span></Accordion.Header>
                    <Accordion.Body>
                      <div className="px-3">
                        <span className='text-white px-3 mb-3 py-1 rounded-4 fw-600 font-os fz-13px bg-secondary'>Sub Checklist / {checkListData?.taskType == "DAILYTASK" ? "Daily Task" : ""} {checkListData?.taskType == "WEEKLYTASK" ? "Weekly Task" : ""} {checkListData?.taskType == "MONTHLYTASK" ? "Monthly Task" : ""}</span>
                        <table className="table-cust">
                          <thead>

                            <tr>
                              <th>
                                SNo
                              </th>
                              <th>
                                Title
                              </th>
                              <th>
                                Description
                              </th>
                              {/* <th>
                                TaskState
                              </th> */}
                              <th className='w-25'>
                                Action
                              </th>
                              {/* <th>
                                  Status
                                </th> */}
                            </tr>

                          </thead>
                          <tbody >

                            {checkListData?.subCheckList?.length ?
                              checkListData?.subCheckList?.map((subCheckList, indx) => (
                                <tr key={subCheckList?.id + indx}>
                                  <td>
                                    {(indx + 1).toString().padStart(2, '0')}
                                  </td>
                                  <td >
                                    {subCheckList.title}

                                  </td>
                                  <td >
                                    {subCheckList.description}

                                  </td>


                                  <td >
                                    <div className="d-flex justify-content-around">

                                      <div className='APPROVED'>Approved</div>

                                    </div>
                                  </td>

                                  {/* <td className={`${subCheckList?.status}`}>

                                      {subCheckList?.status}
                                    </td> */}
                                </tr>
                              ))
                              : <tr>
                                <td colSpan={5} className="text-muted">
                                  No SubCheckList Available
                                </td>

                              </tr>
                            }
                          </tbody>
                        </table>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                ))
              ))
            } </Accordion>
          </div >
        </LoaderWrapper>
      </div>
      <JsonViewer data={allTaskData} />

    </>
  )
}

export default ApprovedTasksList


import Swal from "sweetalert2"
import { API_METHOD } from "./apiConstants"

export const API_CALL = async (req) => {
    const usrDetail = JSON.parse(localStorage.getItem("userDetail"));

    let apiHeader = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
    if (usrDetail?.token) {
        apiHeader = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'redirect': 'follow',
            'Authorization': 'Bearer ' + usrDetail?.token
        }
    }
    if (req.headers) {
        apiHeader = req.headers
    }
    // ========Getting Api URL,method,body=========
    let apiUrl = req.apiUrl
    let apiMethod = req.method
    let apiRequestBody = req.requestBodyParams
    // ========Getting Api URL,method,body End=========

    const requestOptionsPOST = {
        method: apiMethod,
        headers: apiHeader,
        body: apiRequestBody
    }
    const requestOptionsGET = {
        method: apiMethod,
        headers: apiHeader,
    }
    const requestOptionsDELETE = {
        method: apiMethod,
        headers: apiHeader,
        body: apiRequestBody
    }
    const requestOptionsPUT = {
        method: apiMethod,
        headers: apiHeader,
        body: apiRequestBody
    }
    // =========Post=======
    if (apiMethod === API_METHOD.POST) {
        console.log("POST_____");
        let response = fetch(apiUrl, requestOptionsPOST)
            .then(response => response.json())
            .then(result => {
                console.log("Response for Request \n" + apiUrl + " with body \n" + JSON.stringify(requestOptionsPOST), result)
                if (result.statusCode === 200) {
                    return result;
                } else if (result.statusCode > 200) {
                    Swal.fire({
                        icon: 'error',
                        title: result?.message,
                        text: result?.details,

                    })
                }

            })
            .catch(error => {
                console.log("Error Occured for Request \n" + apiUrl + " with body \n" + JSON.stringify(requestOptionsPOST, null, 4), error)
                Swal.fire({
                    icon: 'error',
                    title: error.message,
                    text: 'Something went wrong!',

                })
                return error;
            });
        return response;
    }
    // =========GET=======

    if (apiMethod === API_METHOD.GET) {

        let response = fetch(apiUrl, requestOptionsGET)
            .then(response => response.json())
            .then(result => {
                console.log("Response for Request \n" + apiUrl + "with result", result);
                if (result.statusCode === 200) {
                    return result
                } else if (result.statusCode > 200) {
                    if (result?.statusCode == 403) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Session was expired!',
                            text: 'Please try to re-login',
                            showCancelButton: false,
                            confirmButtonText: 'OK',
                            allowOutsideClick: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                // Clear local storage
                                localStorage.clear();
                                // Reload the page
                                window.location.reload();
                            }
                        });
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: result?.message,
                            text: "Something went wrong!",

                        })
                    }

                }
            })
            .catch(error => {
                console.log("Error Occured for Request \n" + apiUrl + " with body \n" + error)
                Swal.fire({
                    icon: 'error',
                    title: error.message,
                    text: 'Something went wrong!',

                })
                return error;
            });
        return response;
    }
    // =========DELETE=======

    if (apiMethod === API_METHOD.DELETE) {

        let response = fetch(apiUrl, requestOptionsDELETE)
            .then(response => response.json())
            .then(result => {
                console.log("Response for Request \n", result)
                if (result.statusCode === 200) {
                    return result
                } else if (result.statusCode > 200) {
                    Swal.fire({
                        icon: 'error',
                        title: result?.message,
                        text: result?.details,

                    })
                }
            })
            .catch(error => {
                console.log("Error Occured for Request \n" + JSON.stringify(requestOptionsDELETE) + " with body \n" + error)
                Swal.fire({
                    icon: 'error',
                    title: error.message,
                    text: 'Something went wrong!',

                })
                return error;
            });

        return response;
    }

    //===========PUT==========
    if (apiMethod === API_METHOD.PUT) {
        console.log("PUT____________________");
        let response = fetch(apiUrl, requestOptionsPUT)
            .then(response => response.json())
            .then(result => {
                console.log("Response for Request \n" + apiUrl, result)
                if (result.statusCode === 200) {
                    return result
                } else if (result.statusCode > 200) {
                    Swal.fire({
                        icon: 'error',
                        title: result?.message,
                        text: result?.details,

                    })
                }
            })
            .catch(error => {
                console.log("Error Occured for Request \n" + JSON.stringify(requestOptionsDELETE) + " with body \n" + error)
                Swal.fire({
                    icon: 'error',
                    title: error.message,
                    text: 'Something went wrong!',
                })
                return error;

            });
        return response;
    }


}

